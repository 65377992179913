import React, { useEffect, useState } from "react";
import style from "./RewardSetting.module.css";
import { baseUrl } from "../Url";
import { Alert, IconButton, Snackbar } from "@mui/material";
import { IoCreateOutline } from "react-icons/io5";
import { LuFileEdit } from "react-icons/lu";
import { AiOutlineDelete } from "react-icons/ai";
import { MdClose } from "react-icons/md";
import { GiLaurelsTrophy } from "react-icons/gi";
import { AnimationData } from "../../Animations/AnimationData";
import Lottie from "lottie-react";

const RewardSetting = () => {
  const [assessmentAttemptPoint, setAssessmentAttemptPoint] = useState("");
  const [assessmentCorrectPoint, setAssessmentCorrectPoint] = useState("");
  const [lessonReadingPoint, setLessonReadingPoint] = useState("");
  const [queryAskedPoint, setQueryAskedPoint] = useState("");
  const [rewardSettingsId, setRewardSettingsId] = useState("");
  const [validationErrorDisplay, setValidationErrorDisplay] = useState(false);
  const [validationErrorMessage, setValidationErrorMessage] = useState("");
  const [successMessageDisplay, setSuccessMessageDisplay] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [rewardSettingView, setRewardSettingView] = useState(false);
  const [rewardSettingEditView, setRewardSettingEditView] = useState(false);
  const [rewardSettingDeleteView, setRewardSettingDeleteView] = useState(false);

  const toggleTooltip = () => {
    setTooltipVisible(!tooltipVisible);
  };

  async function rewardPointsGet() {
    const orgId = localStorage.getItem("ORGID");
    await fetch(`${baseUrl}/reward/settings/list`, {
      method: "POST",
      body: JSON.stringify({
        org_id: orgId,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.data.length > 0) {
          setAssessmentAttemptPoint(data.data[0].assessment_attempt_point);
          setAssessmentCorrectPoint(data.data[0].assessment_correct_point);
          setLessonReadingPoint(data.data[0].lesson_reading_point);
          setQueryAskedPoint(data.data[0].query_asked_point);
          setRewardSettingsId(data.data[0].reward_setting_id);
        }
      });
  }

  async function rewardPointCreation() {
    if (assessmentAttemptPoint === "") {
      setValidationErrorMessage("Please fill the fields");
      setValidationErrorDisplay(true);
    } else if (assessmentCorrectPoint === "") {
      setValidationErrorMessage("Please fill the fields");
      setValidationErrorDisplay(true);
    } else if (lessonReadingPoint === "") {
      setValidationErrorMessage("Please fill the fields");
      setValidationErrorDisplay(true);
    } else if (queryAskedPoint === "") {
      setValidationErrorMessage("Please fill the fields");
      setValidationErrorDisplay(true);
    } else {
      const orgId = localStorage.getItem("ORGID");
      await fetch(`${baseUrl}/reward/settings/create`, {
        method: "POST",
        body: JSON.stringify({
          org_id: orgId,
          assessment_attempt_point: assessmentAttemptPoint,
          assessment_correct_point: assessmentCorrectPoint,
          lesson_reading_point: lessonReadingPoint,
          query_asked_point: queryAskedPoint,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((res) => res.json())
        .then((data) => {
          console.log(data);
          if (data.status === true) {
            setSuccessMessageDisplay(true);
            setSuccessMessage("Reward setting is created successfully");
            rewardPointsGet();
            setRewardSettingView(false);
          }
        });
    }
  }

  async function rewardPointEdit() {
    if (assessmentAttemptPoint === "") {
      setValidationErrorMessage("Please fill the fields");
      setValidationErrorDisplay(true);
    } else if (assessmentCorrectPoint === "") {
      setValidationErrorMessage("Please fill the fields");
      setValidationErrorDisplay(true);
    } else if (lessonReadingPoint === "") {
      setValidationErrorMessage("Please fill the fields");
      setValidationErrorDisplay(true);
    } else if (queryAskedPoint === "") {
      setValidationErrorMessage("Please fill the fields");
      setValidationErrorDisplay(true);
    } else {
      await fetch(`${baseUrl}/reward/settings/edit`, {
        method: "POST",
        body: JSON.stringify({
          reward_id: rewardSettingsId,
          assessment_attempt_point: assessmentAttemptPoint,
          assessment_correct_point: assessmentCorrectPoint,
          lesson_reading_point: lessonReadingPoint,
          query_asked_point: queryAskedPoint,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((res) => res.json())
        .then((data) => {
          console.log(data);
          if (data.status === true) {
            setSuccessMessageDisplay(true);
            setSuccessMessage("Reward setting is edited successfully");
            rewardPointsGet();
          }
        });
    }
  }

  async function rewardPointDelete() {
    await fetch(`${baseUrl}/reward/settings/delete`, {
      method: "POST",
      body: JSON.stringify({
        reward_id: rewardSettingsId,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === true) {
          setSuccessMessageDisplay(true);
          setSuccessMessage("Reward setting is deleted successfully");
          rewardPointsGet();
          setRewardSettingsId("");
          setAssessmentAttemptPoint("");
          setAssessmentCorrectPoint("");
          setLessonReadingPoint("");
          setQueryAskedPoint("");
        }
      });
  }

  useEffect(() => {
    rewardPointsGet();
    console.log(rewardSettingsId);
  }, [rewardSettingsId]);

  return (
    <div className={style.container}>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={successMessageDisplay}
        sx={{ width: "20rem" }}
        autoHideDuration={3000}
        onClose={() => {
          setSuccessMessageDisplay(false);
        }}
      >
        <Alert
          severity="success"
          variant="filled"
          sx={{ width: "100%", fontSize: "1.15rem" }}
        >
          {successMessage}
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={validationErrorDisplay}
        sx={{ width: "20rem" }}
        autoHideDuration={3000}
        onClose={() => {
          setValidationErrorDisplay(false);
        }}
      >
        <Alert
          variant="filled"
          severity="error"
          sx={{ width: "100%", fontSize: "1.15rem" }}
        >
          {validationErrorMessage}
        </Alert>
      </Snackbar>
      <div className={style.rewardHeader}>
        <div className={style.headerText}>
          <p>Reward Settings</p>
        </div>
      </div>
      <div className={style.rewardContainer}>
        <div className={style.rewardControls}>
          <div className={style.controlHeader}>
            <p>Add your settings</p>
          </div>
          <div className={style.controls}>
            <div className={style.controlGrid}>
              <div className={style.rewardSettings} onClick={toggleTooltip}>
                <div className={style.settingLottie}>
                  <Lottie
                    animationData={AnimationData.reward}
                    loop={false}
                    duration
                    autoplay
                  />
                </div>
                <div className={style.settingContent}>
                  <p>Reward Setting</p>
                </div>
              </div>
              <div
                className={`${style.rewardTooltip} ${
                  tooltipVisible ? style.showTooltip : ""
                }`}
              >
                <IoCreateOutline
                  onClick={() => {
                    toggleTooltip();
                    setRewardSettingView(true);
                  }}
                />
                <LuFileEdit
                  onClick={() => {
                    toggleTooltip();
                    setRewardSettingEditView(true);
                  }}
                />
                <AiOutlineDelete
                  onClick={() => {
                    toggleTooltip();
                    setRewardSettingDeleteView(true);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {rewardSettingView ? (
        <>
          <div className={style.Overlay}></div>
          <div className={style.controlSection}>
            <div className={style.header}>
              <div className={style.rewardHeaderText}>
                <p>Reward Settings</p>
              </div>
              <div className={style.rewardHeaderClose}>
                <MdClose
                  onClick={() => {
                    setRewardSettingView(false);
                  }}
                />
              </div>
            </div>
            {rewardSettingsId === "" ? (
              <>
                <div className={style.mainSection}>
                  <div className={style.sectionValues}>
                    <p>Assessment attempt point</p>
                    <input
                      type="text"
                      name=""
                      id=""
                      value={assessmentAttemptPoint}
                      onChange={(e) => {
                        setAssessmentAttemptPoint(e.target.value);
                      }}
                    />
                  </div>
                  <div className={style.sectionValues}>
                    <p>Assessment correct point</p>
                    <input
                      type="text"
                      name=""
                      id=""
                      value={assessmentCorrectPoint}
                      onChange={(e) => {
                        setAssessmentCorrectPoint(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className={style.mainSection}>
                  <div className={style.sectionValues}>
                    <p>Lesson reading point</p>
                    <input
                      type="text"
                      name=""
                      id=""
                      value={lessonReadingPoint}
                      onChange={(e) => {
                        setLessonReadingPoint(e.target.value);
                      }}
                    />
                  </div>
                  <div className={style.sectionValues}>
                    <p>Query asked point</p>
                    <input
                      type="text"
                      name=""
                      id=""
                      value={queryAskedPoint}
                      onChange={(e) => {
                        setQueryAskedPoint(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className={style.createButton}>
                  <button
                    onClick={() => {
                      rewardPointCreation();
                    }}
                  >
                    Create
                  </button>
                </div>
              </>
            ) : (
              <div className={style.creationExist}>
                <Alert severity="info" sx={{ width: "20rem" }}>
                  Setting is already created
                </Alert>
              </div>
            )}
          </div>
        </>
      ) : (
        ""
      )}

      {rewardSettingEditView ? (
        <>
          <div className={style.Overlay}></div>
          <div className={style.controlSection}>
            <div className={style.header}>
              <div className={style.rewardHeaderText}>
                <p>Reward Settings</p>
              </div>
              <div className={style.rewardHeaderClose}>
                <MdClose
                  onClick={() => {
                    setRewardSettingEditView(false);
                  }}
                />
              </div>
            </div>
            {rewardSettingsId !== "" ? (
              <>
                <div className={style.mainSection}>
                  <div className={style.sectionValues}>
                    <p>Assessment attempt point</p>
                    <input
                      type="text"
                      name=""
                      id=""
                      value={assessmentAttemptPoint}
                      onChange={(e) => {
                        setAssessmentAttemptPoint(e.target.value);
                      }}
                    />
                  </div>
                  <div className={style.sectionValues}>
                    <p>Assessment correct point</p>
                    <input
                      type="text"
                      name=""
                      id=""
                      value={assessmentCorrectPoint}
                      onChange={(e) => {
                        setAssessmentCorrectPoint(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className={style.mainSection}>
                  <div className={style.sectionValues}>
                    <p>Lesson reading point</p>
                    <input
                      type="text"
                      name=""
                      id=""
                      value={lessonReadingPoint}
                      onChange={(e) => {
                        setLessonReadingPoint(e.target.value);
                      }}
                    />
                  </div>
                  <div className={style.sectionValues}>
                    <p>Query asked point</p>
                    <input
                      type="text"
                      name=""
                      id=""
                      value={queryAskedPoint}
                      onChange={(e) => {
                        setQueryAskedPoint(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className={style.editButton}>
                  <button
                    onClick={() => {
                      rewardPointEdit();
                      setRewardSettingEditView(false);
                    }}
                  >
                    Edit
                  </button>
                </div>
              </>
            ) : (
              <div className={style.creationExist}>
                <Alert severity="info" sx={{ width: "20rem" }}>
                  Please create a setting
                </Alert>
              </div>
            )}
          </div>
        </>
      ) : (
        ""
      )}

      {rewardSettingDeleteView ? (
        <>
          <div className={style.Overlay}></div>
          <div className={style.DeletePopup}>
            <div className={style.deleteClose}>
              <MdClose
                onClick={() => {
                  setRewardSettingDeleteView(false);
                }}
              />
            </div>
            <div className={style.deleteSection}>
              <div className={style.popupText}>
                <p>Are you sure you want to delete?</p>
              </div>
              <div className={style.deleteButtons}>
                <button
                  className={style.orgDelete}
                  onClick={() => {
                    setRewardSettingDeleteView(false);
                    rewardPointDelete();
                  }}
                >
                  Delete
                </button>

                <button
                  className={style.orgDeleteCancel}
                  onClick={() => {
                    setRewardSettingDeleteView(false);
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
    </div>
  );
};

export default RewardSetting;
