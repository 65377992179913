import React, { useEffect } from "react";
import style from "./ExistingUser.module.css";
import { FiSearch } from "react-icons/fi";
import { AiOutlineClose } from "react-icons/ai";
import { MdKeyboardArrowDown } from "react-icons/md";
import { MdKeyboardArrowUp } from "react-icons/md";
import { useState } from "react";
import { baseUrl } from "../Url";
import { useLocation } from "react-router-dom";
import { ClubGet } from "../../action/List";
import { useDispatch, useSelector } from "react-redux";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import SecondLoader from "../SecondLoader/SecondLoader";
import Pagination from "../Pagination/Pagination";
import { MdOutlinePersonSearch } from "react-icons/md";
import Checkbox from "@mui/material/Checkbox";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { BsTruckFlatbed } from "react-icons/bs";

const ExistingUser = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const state = location.state;
  const orgId = state.org;

  const { clubs } = useSelector((state) => state.clubData);
  const [clear, setClear] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [userDetail, setUserDetail] = useState([]);
  const [clubDetatil, setClubDetail] = useState([]);
  const [userId, setUserId] = useState("");
  const [clubListStates, setClubListStates] = useState([]);
  const [selectedClub, setSelectedClub] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [paginationContent, setPainationContent] = useState(false);
  const [courseMapping, setCourseMapping] = useState(false);
  const [userMappingFail, setUserMappingFail] = useState(false);
  const [userMappingSuccess, setUserMappingSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const toggleClubList = (index) => {
    const updatedStates = [...clubListStates];
    updatedStates[index] = !updatedStates[index];
    setClubListStates(updatedStates);
  };

  const itemsPerPage = 8; // Adjust as needed
  const users = userDetail;
  const totalUsers = users.length;

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  const startIndex = currentPage * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const displayUsers = users.slice(startIndex, endIndex);

  const handleInputChange = (e) => {
    const newValue = e.target.value;
    setSearchValue(newValue);
    setLoading(true);
    setPainationContent(true);

    // Update the 'clear' state based on the input value
    setClear(newValue.length > 0);

    // Call the search function with the updated value
    userSearch(newValue);
  };

  const handleClearClick = () => {
    setSearchValue("");
    setClear(false);
    setUserDetail([]);
    setPainationContent(false)
  };

  const handleCheckboxChange = (clubID) => {
    const newSelectedClubs = selectedClub.includes(clubID)
      ? selectedClub.filter((id) => id !== clubID)
      : [...selectedClub, clubID];

    setSelectedClub(newSelectedClubs);
  };
useEffect(()=>{
console.log(selectedClub);
},[selectedClub])
  //user list search

  async function userSearch(value) {
    await fetch(`${baseUrl}/search/user`, {
      method: "POST",
      body: JSON.stringify({
        org_id: orgId,
        search: value,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setUserDetail(data.data);
        setLoading(false);
      });
  }

  //club list

  async function userCLub(id) {
    await fetch(`${baseUrl}/user/club/details`, {
      method: "POST",
      body: JSON.stringify({
        user_id: id,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setClubDetail(data.data[0].club_details);
      });
  }

  //user mapping

  async function userMapping() {
    console.log(selectedClub);
    await fetch(`${baseUrl}/map/user/club`, {
      method: "POST",
      body: JSON.stringify({
        user_id: userId,
        club_id: selectedClub,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === true) {
          setUserMappingSuccess(true)
          setCourseMapping(false)
          userSearch(searchValue)
        }
      });
  }

  useEffect(() => {
    dispatch(ClubGet(orgId));
  }, []);

  return (
    <div className={style.Container}>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={
          userMappingSuccess
        }
        sx={{ zIndex: 999999 }}
        autoHideDuration={3000}
        onClose={() => {
         setUserMappingSuccess(false)
        }}
      >
        <Alert
          severity="success"
          variant="filled"
          sx={{ width: "100%", fontSize: "1.15rem" }}
        >
          {"Club mapped successfully"}
        </Alert>
      </Snackbar>

<Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={userMappingFail}
        sx={{ width: "15rem", zIndex: 999999 }}
        autoHideDuration={3000}
        onClose={() => {
          setUserMappingFail(false);
        }}
      >
        <Alert
          variant="filled"
          severity="error"
          sx={{ width: "100%", fontSize: "1.15rem" }}
        >
          {errorMessage}
        </Alert>
      </Snackbar>
      <div className={style.Header}>
        <div className={style.Title}>
          <p>Add User to Club/Batch</p>
        </div>
      </div>
      <div className={style.Search}>
        <div className={style.searchSection}>
          <div className={style.searchHeader}>
            <input
              value={searchValue}
              type="text"
              placeholder="Search with name,club..."
              onChange={handleInputChange}
            />
            {clear ? (
              <AiOutlineClose onClick={handleClearClick} />
            ) : (
              <MdOutlinePersonSearch />
            )}
          </div>
        </div>
      </div>
      {loading ? (
        <SecondLoader />
      ) : (
        <div className={style.SearchResult}>
          <div className={style.Grid}>
            {displayUsers.length > 0 &&
              displayUsers.map((detail, index) => {
                return (
                  <div className={style.card}>
                    <div className={style.cardTop}>
                      <div className={style.userName}>
                        <p>UserName</p>
                        <span>:</span>
                        <p>{detail.Username}</p>
                      </div>
                      <div className={style.role}>
                        <p>Role</p>
                        <span>:</span>
                        <p>{detail.role}</p>
                      </div>
                      <div className={style.organisation}>
                        <p>Organization</p>
                        <span>:</span>
                        <p>{detail.organization_name}</p>
                      </div>
                    </div>

                    <div className={style.cardBottom}>
                     
                     
                      {/* <div className={style.bottomList}>
                    <div className={style.listSelection}>
                      <p>Add Club</p>
                      {clubListStates[index] ? (
                        <MdKeyboardArrowUp
                          onClick={() => toggleClubList(index)}
                        />
                      ) : (
                        <MdKeyboardArrowDown
                          onClick={() => {
                            toggleClubList(index);
                            userCLub(detail._id);
                            const userID = detail._id;
                            setUserId(userID);
                          }}
                        />
                      )}
                    </div>
                    {clubListStates[index] ? (
                      <div className={style.listValue}>
                        <div className={style.currentClub}>
                          <p className={style.clubName}>Current Clubs:</p>
                          {clubDetatil.length > 0 &&
                            clubDetatil.map((club) => {
                              return (
                                <p className={style.clubNames}>
                                  {club.clubname}
                                </p>
                              );
                            })}
                        </div>
                        <div className={style.selectClub}>
                          <select
                            name=""
                            id=""
                            onChange={(e) => {
                              const selectedClubId = e.target.value;
                              setSelectedClub(selectedClubId);
                            }}
                          >
                            <option value="">Select club</option>
                            {clubs.length > 0 &&
                              clubs.map((item, index) => {
                                return (
                                  <option value={item.ClubID}>
                                    {item.ClubName}
                                  </option>
                                );
                              })}
                          </select>
                        </div>
                        <div className={style.addButton}>
                          <button
                            onClick={() => {
                              userMapping(detail._id);
                            }}
                          >
                            Add
                          </button>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div> */}
                    </div>
                    <div className={style.addButton}>
                      <button
                        onClick={() => {
                          setCourseMapping(true)
                          setUserId(detail._id)
                          setSelectedClub(detail.club_id)
                        }}
                      >
                        Add
                      </button>
                    </div>
                  </div>
                );
              })}
          </div>
          {paginationContent ? (
            <Pagination
              pageCount={Math.ceil(totalUsers / itemsPerPage)}
              onPageChange={handlePageChange}
            />
          ) : (
            ""
          )}
        </div>
        
      )}
      {courseMapping ? (
        <>
          <div className={style.Overlay}></div>
          <div className={style.courseMapping}>
            <div className={style.courseMapClose}>
              <AiOutlineClose
                onClick={() => {
                  setCourseMapping(false);
                  setSelectedClub([])
                }}
              />
            </div>
            <div className={style.courseMapSection}>
            <FormControl sx={{ width: "100%", margin: "0 0.25rem" }}>
                        <InputLabel id="demo-simple-select-label">
                          Select Club/Batch
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Select Club/Batch"
                          multiple
                          value={selectedClub}
                          onChange={(event) => setSelectedClub(event.target.value)} // Add this prop
                        >
                          {clubs.length > 0 &&
                            clubs.map((club, index) => {
                              const isSelected = selectedClub.includes(club.ClubID)
                              return (
                                <MenuItem
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                }}
                                key={club.ClubID}
                                value={club.ClubID}
                              >
                                {" "}
                                {club.ClubName}
                                <Checkbox
                                  name={club.ClubID}
                                  id={club.ClubID}
                                  checked={isSelected}
                                  onChange={() => handleCheckboxChange(club.ClubID)}
                                />
                              </MenuItem>
                              );
                            })}
                        </Select>
                      </FormControl>
            </div>
            <div className={style.courseMapButton}>
              <button
                onClick={() => {
                  if (selectedClub.length > 0) {
                    userMapping()
                  }
                  else {
                    setErrorMessage("Select atleast one club")
                    setUserMappingFail(true)
                  }
                }}
                className={style.create}
              >
                Map User
              </button>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
    </div>
  );
};

export default ExistingUser;
