import React from "react";
import { SyncLoader } from "react-spinners";
import style from "./SecondLoader.module.css";

const SecondLoader = () => {
  return (
    <div className={style.Container}>
      <div className={style.Loader}>
        <SyncLoader color="#36D7B7" size={15} margin={5} loading={true} />
      </div>
    </div>
  );
};

export default SecondLoader;
