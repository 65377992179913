import React, { useState, useEffect } from "react";
import style from "./Syllabus.module.css";
import { useNavigate, useLocation } from "react-router-dom";
const Syllabus = () => {
  const key = JSON.parse(localStorage.getItem("access-token"));
  const [popup, setPopup] = useState(false);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [syllabus, setSyllabus] = useState([]);
  const location=useLocation()
  const state=location.state
  const Learning_ID=state&&state.learning
  const navigate = useNavigate();
  const [deletePopup,setDeletePopup]=useState(false)
  const[programTitle,setProgramTitle]=useState("")
  const [learningDescription, setLearningDescription] = useState("");
  const [updatePopup,setUpdatePopup]=useState(false)
  const[learningData,setLearningData]=useState([])



  async function syllabusAdd(e) {
    e.preventDefault();
    setPopup(false);
    await fetch("https://services.1o1learnersclub.com/syllabus/add", {
      method: "POST",
      body: JSON.stringify({
        Name: name,
        Description: description,
        Learning_ID:Learning_ID
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${key}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if(data.status===true){
          syllabusList();
          setName("")
          setDescription("")
        }

      });
  }

  useEffect(()=>{
    // console.log(Learning_ID);
  },[])
  async function syllabusList() {
    await fetch("https://services.1o1learnersclub.com/syllabus/list", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${key}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setSyllabus(data.data);
      });
  }
  async function learningGet() {
    await fetch("https://services.1o1learnersclub.com/learning/get", {
      method: "POST",
      body: JSON.stringify({
        Learning_ID:Learning_ID
      }),
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${key}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setLearningData(data.data[0])
      });
  }

  async function learningDelete(e) {
    e.preventDefault()
    await fetch("https://services.1o1learnersclub.com/learning/delete", {
      method: "POST",
      body: JSON.stringify({
        Learning_ID:Learning_ID
      }),
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${key}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if(data.status===true){
          navigate("/learning")
        }
      });
  }

  async function learningUpdate(e) {
    e.preventDefault()
    await fetch("https://services.1o1learnersclub.com/learning/update", {
      method: "POST",
      body: JSON.stringify({
        Learning_ID:Learning_ID,
        ProgramTitle:programTitle,
        Description:learningDescription
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${key}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if(data.status===true){
          navigate("/learning")
        }
      });
  }

  useEffect(() => {
    syllabusList();
    learningGet()
  }, [key]);
  return (
    <div className={style.Container}>
      {popup ? (
        <>
          <div
            onClick={() => {
              setPopup(false);
            }}
            className={style.Overlay}
          ></div>
          <div className={style.AddressSection}>
            <form action="" onSubmit={syllabusAdd}>
              <input
                type="text"
                value={name}
                required
                onChange={(e) => {
                  setName(e.target.value);
                }}
                placeholder="SyllabusName"
              />
              <input
                type="text"
                value={description}
                required
                onChange={(e) => {
                  setDescription(e.target.value);
                }}
                placeholder="Description"
              />

              <button
                type="submit"
                // onClick={() => {
                //   syllabusAdd()
                //   setPopup(false)
                // }}
              >
                CONFIRM
              </button>
            </form>
          </div>
        </>
      ) : (
        ""
      )}
      {deletePopup?
      <>
      <div
        onClick={() => {
          setDeletePopup(false);
        }}
        className={style.Overlay}
      ></div>
      <div className={style.Popup}>
      <div className={style.popupText}>
              <p>Are you sure you want to Delete?</p>
            </div>
            <div className={style.PopupButton}>
              <button
                onClick={(e) => {
                  e.preventDefault()
                  learningDelete(e)
                  setDeletePopup(false);
                }}
              >
                YES
              </button>
              <button
                onClick={() => {

                  setDeletePopup(false);
                }}
              >
                NO
              </button>
            </div>
      </div>
    </>
      :''
      }
      {updatePopup ? (
        <>
          <div
            onClick={() => {
              setUpdatePopup(false);
            }}
            className={style.Overlay}
          ></div>
          <div className={style.AddressSection}>
            <form action="" onSubmit={learningUpdate}>
              <input
                type="text"
                value={programTitle}
                required
                onChange={(e) => {
                  setProgramTitle(e.target.value);
                }}
                placeholder="ProgramTitle"
              />
              <input
                type="text"
                value={learningDescription}
                required
                onChange={(e) => {
                  setLearningDescription(e.target.value);
                }}
                placeholder="Description"
              />

              <button
                type="submit"
                // onClick={() => {
                //   syllabusAdd()
                //   setPopup(false)
                // }}
              >
                Update
              </button>
            </form>
          </div>
        </>
      ) : (
        ""
      )}

      <div className={style.HeaderDiv}>
        <div className={style.Title}>
          <p>{learningData.ProgramTitle}</p>
        </div>
        <div className={style.HeaderButton}>
          <button
          onClick={() => {
            setUpdatePopup(true);
          }}>Update</button>
          <button onClick={() => {
              setDeletePopup(true);
            }}>Delete</button>
        </div>
      </div>
      <div className={style.Header}>
        <div className={style.HeaderText}>
          <p>SyllabusList</p>
        </div>
        <div className={style.HeadButton}>
          <button
            onClick={() => {
              setPopup(true);
            }}
          >
            Create
          </button>
        </div>
      </div>
      <div className={style.Syllabus}>
        {syllabus && syllabus.length > 0
          ? syllabus.map((item) => {
              return (
                <div
                  className={style.Cards}
                  onClick={() => {
                    navigate("/lesson",{
                      state:{
                        Syllabus_ID:item.Syllabus_ID
                      }
                    });
                  }}
                >
                  <p>{item.Name}</p>
                </div>
              );
            })
          : ""}
      </div>
    </div>
  );
};

export default Syllabus;
