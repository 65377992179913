import React from 'react'
import ReactPaginate from 'react-paginate';
import style from "./Pagination.module.css"
import { MdOutlineSkipPrevious } from "react-icons/md";
import { RiSkipForwardMiniLine } from "react-icons/ri";


const Pagination = ({ pageCount, onPageChange, currentPage }) => {
  return (
    <ReactPaginate
      pageCount={pageCount}
      pageRangeDisplayed={5} // Number of visible page links
      marginPagesDisplayed={1} // Number of page links to display at the beginning and end
      onPageChange={onPageChange}
      containerClassName={style.pagination}
      activeClassName={style.Active}
      previousLabel={<MdOutlineSkipPrevious />}
      nextLabel={<RiSkipForwardMiniLine />
      }
      breakLabel={'...'}
      pageClassName={style.page}
      previousClassName={style.previous}
      nextClassName={style.next}
    />
  )
}

export default Pagination