import React, { useEffect, useState, useRef } from "react";
import style from "./Gallery.module.css";
import { FaTableList } from "react-icons/fa6";
import { RxGrid } from "react-icons/rx";
import { baseUrl } from "../Url";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { IconButton, TextField } from "@mui/material";
import { MdClose,MdDelete } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { MdPhotoCamera } from "react-icons/md";

const Gallery = () => {
  const { profile } = useSelector((state) => state.profileData);

  const inputFileRef = useRef(null);

  const [gridView, setGridView] = useState(true);
  const [uploadPopup, setUploadPopUp] = useState(false);
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState("");
  const [fileSize, setFileSize] = useState("");
  const [fileType, setFileType] = useState("");
  const [title, setTitle] = useState("");
  const [caption, setCaption] = useState("");
  const [discription, setDiscription] = useState("");
  const [detailsPopup, setDetailsPopup] = useState(false);
  const [orgID, setOrgID] = useState("");
  const [userName, setUserName] = useState("");
  const [data, setData] = useState([]);
  const [errorValue, setErrorValue] = useState("");
  const [validation, setValidation] = useState(false);
  const [editFile, setEditFile] = useState(null);
  const [editFileName, setEditFileName] = useState("");
  const [editFileSize, setEditFileSize] = useState("");
  const [editFileType, setEditFileType] = useState("");
  const [editTitle, setEditTitle] = useState("");
  const [editCaption, setEditCaption] = useState("");
  const [editDiscription, setEditDiscription] = useState("");
  const [alternativeText, setAlternativeText] = useState("");
  const [editAlternativeText, setEditAlternativeText] = useState("");
  const [editUserName, setEditUserName] = useState("");
  const [uploadDate, setUploadDate] = useState("");
  const[galleyID,setGalleryID]=useState("")
  const[uploadSuccess,setUploadSuccess]=useState(false)
  const[updateSuccess,setUpdateSuccess]=useState(false)
  const[search,setSearch]=useState("")
  const[deletePopup,setDeletePopup]=useState(false)



  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      // Check if the selected file is an image
      if (selectedFile.type && selectedFile.type.indexOf("image") === -1) {
        console.log("Please select an image file.");
        return;
      }
      setFile(selectedFile);
      setFileName(selectedFile.name);
      setFileSize(selectedFile.size);
      setFileType(selectedFile.type);
    }
  };

  const handleEditFileChange = async (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      const formData = new FormData();
      formData.append("image", selectedFile);
      formData.append("org_id", orgID);
      try {
        const response = await fetch(`${baseUrl}/to/url/gallery`, {
          method: "POST",
          body: formData,
        });

        if (response.ok) {
          const responseData = await response.json(); // Assuming the API returns the URL in JSON format
          const imageUrl = responseData.data[0].image_url;
          setEditFile(imageUrl);
        } else {
          // Handle error response from the API
          console.error("Failed to upload image", response);
        }
      } catch (error) {
        // Handle network errors or other exceptions
        console.error("Error uploading image:", error);
      }

      // Check if the selected file is an image
      if (selectedFile.type && selectedFile.type.indexOf("image") === -1) {
        console.log("Please select an image file.");
        return;
      }
      setEditFileName(selectedFile.name);
      setEditFileSize(selectedFile.size);
      setEditFileType(selectedFile.type);
    }
  };

  const handleImageClick = () => {
    inputFileRef.current.click();
  };

  const validateForm = () => {
    if (file === null) {
      setErrorValue("Please choose file");
      return false;
    }
    if (title.trim() === "") {
      setErrorValue("Title missing");
      return false;
    }
    if (discription.trim() === "") {
      setErrorValue("Discription missing");
      return false;
    }
    if (caption.trim() === "") {
      setErrorValue("Caption missing");
      return false;
    }

    return true;
  };

  async function imageUpload() {
    let formData = new FormData();
    formData.append("image", file);
    formData.append("org_id", orgID);
    formData.append("title", title);
    formData.append("discription", discription);
    formData.append("caption", caption);
    formData.append("file_type", fileType);
    formData.append('file_name', fileName);
    formData.append('file_size', fileSize);
    formData.append('alternative_text', alternativeText);
    formData.append("uploaded_by", userName);
    if (!validateForm()) {
      setValidation(true);
      return;
    } else {
      await fetch(`${baseUrl}/gallery/file/upload`, {
        method: "POST",
        body: formData,
      })
        .then((res) => res.json())
        .then((data) => {
          console.log(data);
          if (data.status === true) {
            setUploadPopUp(false);
            setUploadSuccess(true)
            setFile(null);
            setTitle("");
            setDiscription("");
            setCaption("");
            setFileName("");
            setFileType("");
            setFileSize("");
            filesGet();
          }
        });
    }
  }

  async function filesGet() {
    await fetch(`${baseUrl}/gallery/file/get`, {
      method: "POST",
      body: JSON.stringify({
        org_id: orgID,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setData(data.data);
      });
  }

  async function individualDetails(id) {
    await fetch(`${baseUrl}/gallery/file/get/individual`, {
      method: "POST",
      body: JSON.stringify({
        gallery_id: id,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.data.length > 0) {
          const utcTimestamp = new Date(data.data[0].CreatedTimestamp.$date);
          const localTimestamp = utcTimestamp.toLocaleString();
          setEditFile(data.data[0].file_url || null);
          setEditFileName(data.data[0].file_name || "");
          setEditFileType(data.data[0].type || "");
          setEditFileSize(data.data[0].file_size || "");
          setEditCaption(data.data[0].caption || "");
          setEditDiscription(data.data[0].discription || "");
          setEditTitle(data.data[0].title || "");
          setEditAlternativeText(data.data[0].alternative_text || "");
          setEditUserName(data.data[0].uploaded_by || "");
          setUploadDate(localTimestamp);
          setDetailsPopup(true);
        }
      });
  }
  async function fileUpdate() {
    await fetch(`${baseUrl}/gallery/file/individual/edit`, {
      method: "POST",
      body: JSON.stringify({
        gallery_id: galleyID,
        discription: editDiscription,
        file_url: editFile,
        title: editTitle,
        caption: editCaption,
        file_type: editFileType,
        file_name: editFileName,
        file_size: editFileSize,
        alternative_text: editAlternativeText,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status=true) {
          setEditFile(null);
          setEditFileName( "");
          setEditFileType("");
          setEditFileSize( "");
          setEditCaption( "");
          setEditDiscription( "");
          setEditTitle( "");
          setEditAlternativeText( "");
          setEditUserName( "");
          setDetailsPopup(false);
          setGalleryID("")
          setUpdateSuccess(true)
        }
      });
  }

  async function filesSearch() {
    await fetch(`${baseUrl}/gallery/file/search`, {
      method: "POST",
      body: JSON.stringify({
        search:search,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setData(data.data);
      });
  }
  async function filesDelete() {
    await fetch(`${baseUrl}/gallery/file/individual/delete`, {
      method: "POST",
      body: JSON.stringify({
        gallery_id: galleyID,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if(data.status=true){
          console.log(data);
          setDeletePopup(false)
          setDeletePopup(false)
        }
      });
  }

  useEffect(() => {
    const newOrgID = profile?.org_id;
    console.log(newOrgID);
    setOrgID(newOrgID);
    setUserName(profile?.Username);
  }, [profile]);

  useEffect(() => {
    if (orgID !== "") {
      filesGet();
    }
  }, [orgID]);

  useEffect(()=>{
    if(search!==""){
      filesSearch()
    }
    else{
      filesGet()
    }

  },[search])

  return (
    <div className={style.Container}>
      <Snackbar
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={updateSuccess || uploadSuccess }
        autoHideDuration={3000}
        onClose={() => {
          setUpdateSuccess(false)
          setUploadSuccess(false)
        }}
      >
        <Alert
          severity="success"
          variant="filled"
          sx={{ width: "100%", fontSize: "1.15rem" }}
        >
          {updateSuccess
            ? "File updated successfully"
            : uploadSuccess
              ? "File uploaded successfully"
                : ""}
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={validation}
        sx={{ width: "25rem" }}
        autoHideDuration={3000}
        onClose={() => {
          setValidation(false);
        }}
      >
        <Alert
          variant="filled"
          severity="error"
          sx={{ width: "100%", fontSize: "1.1rem" }}
        >
          {errorValue}
        </Alert>
      </Snackbar>
      <div className={style.Header}>
        <div className={style.headerText}>
          <h5>Gallery</h5>
        </div>
        <div className={style.headerButton}>
          <button
            onClick={() => {
              setUploadPopUp(true);
            }}
          >
            Upload
          </button>
        </div>
      </div>
      <div className={style.FilterSection}>
        <div className={style.LeftSection}>
          <div className={style.ViewContainer}>
            <div
              className={`${style.ListView} ${!gridView ? style.active : ""}`}
            >
              <IconButton
                onClick={() => setGridView(false)}
                aria-label="ListView"
              >
                <FaTableList />
              </IconButton>
            </div>
            <div
              className={`${style.GridView} ${gridView ? style.active : ""}`}
            >
              <IconButton
                onClick={() => setGridView(true)}
                aria-label="GridView"
              >
                <RxGrid />
              </IconButton>
            </div>
          </div>
          <div className={style.MediaContainer}>
            <FormControl sx={{ width: "100%" }}>
              <InputLabel id="demo-simple-select-label">
                All Media Items
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label=" All Media Items"
              >
                <MenuItem>Image</MenuItem>
                <MenuItem>Video</MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>
        <div className={style.RightSection}>
          <div className={style.SearchContainer}>
            <p>Search</p>
            <TextField sx={{ width: "80%" }} value={search} onChange={(e)=>{
              setSearch(e.target.value)
            }} variant="outlined" />
          </div>
        </div>
      </div>
      <div className={style.GalleryContainer}>
        {gridView ? (
          <div className={style.Grid}>
            {data && data.length > 0
              ? data.map((item, index) => {
                  return (
                    <div
                      key={index}
                      onClick={() => {
                        individualDetails(item.gallery_id);
                        setGalleryID(item.gallery_id)
                      }}
                      className={style.Card}
                    >
                      <img src={item.file_url} />
                    </div>
                  );
                })
              : ""}
          </div>
        ) : (
          <div className={style.List}>
           <table>
            <tbody>
              {data&&data.length>0?data.map((item,index)=>{
                const utcTimestamp = new Date(item.CreatedTimestamp.$date);
                const localTimestamp = utcTimestamp.toLocaleString();
                return(
                  <tr key={index}>
                <td onClick={() => {
                        individualDetails(item.gallery_id);
                        setGalleryID(item.gallery_id)
                      }}>{item.file_name}</td>
                <td>{item.type}</td>
                <td>{localTimestamp}</td>
              </tr>
                )
              }):''}
              
            </tbody>
           </table>
          </div>
        )}
      </div>
      {/*uploadPopup*/}
      {uploadPopup ? (
        <>
          <div className={style.Overlay}></div>
          <div className={style.uploadPopup}>
            <div className={style.uploadPopupHeader}>
              <div className={style.uploadPopupHeaderText}>
                <h5>Upload your file</h5>
              </div>

              <div className={style.uploadPopupHeaderClose}>
                <MdClose
                  onClick={() => {
                    setUploadPopUp(false);
                    setFile(null);
                    setTitle("");
                    setDiscription("");
                    setCaption("");
                    setFileName("");
                    setFileType("");
                    setFileSize("");
                  }}
                />
              </div>
            </div>
            <div className={style.uploadPopupSection}>
              <div className={style.sectionValues}>
                <label htmlFor="" style={{ color: "green" }}>
                  Choose your file
                </label>
                <input
                  type="file"
                  id="fileInput"
                  accept="image/*,video/*" // Accept only image files
                  onChange={handleFileChange}
                />
              </div>
              <div className={style.sectionValues}>
                <label htmlFor="" style={{ color: "green" }}>
                  Alternative text
                </label>
                <input
                  type="text"
                  value={alternativeText}
                  onChange={(e) => {
                    setAlternativeText(e.target.value);
                  }}
                  name=""
                  id=""
                />
              </div>
              <div className={style.sectionValues}>
                <label htmlFor="" style={{ color: "green" }}>
                  Title
                </label>
                <input
                  type="text"
                  value={title}
                  onChange={(e) => {
                    setTitle(e.target.value);
                  }}
                  name=""
                  id=""
                />
              </div>
              <div className={style.sectionValues}>
                <label htmlFor="" style={{ color: "green" }}>
                  Caption
                </label>
                <input
                  type="text"
                  value={caption}
                  onChange={(e) => {
                    setCaption(e.target.value);
                  }}
                  name=""
                  id=""
                />
              </div>
              <div className={style.sectionValues}>
                <label htmlFor="" style={{ color: "green" }}>
                  Discription
                </label>
                <input
                  type="text"
                  value={discription}
                  onChange={(e) => {
                    setDiscription(e.target.value);
                  }}
                  name=""
                  id=""
                />
              </div>
            </div>
            <div className={style.uploadPopupButton}>
              <button
                className={style.add}
                onClick={() => {
                  imageUpload();
                }}
              >
                Add
              </button>
              <button
                className={style.cancel}
                onClick={() => {
                  setUploadPopUp(false);
                  setFile(null);
                  setTitle("");
                  setDiscription("");
                  setCaption("");
                  setFileName("");
                  setFileType("");
                  setFileSize("");
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
      {detailsPopup ? (
        <>
          <div className={style.Overlay}></div>
          <div className={style.DetailsPopup}>
            <div className={style.DetailsPopupHeader}>
              <div className={style.DetailsPopupHeaderText}>
                <h5>Attachment Details</h5>
              </div>

              <div className={style.DetailsPopupHeaderClose}>
                <MdClose
                  onClick={() => {
                    setDetailsPopup(false);
                  }}
                />
              </div>
            </div>
            <div className={style.DetailsPopupSection}>
              <div className={style.DetailsPopupLeftSection}>
                <div className={style.DetailPopupImage}>
                  <img src={editFile} />
                  <input
                    type="file"
                    ref={inputFileRef}
                    style={{ display: "none" }}
                    onChange={handleEditFileChange}
                  />
                </div>
                <div className={style.EditImage}>
                  <button onClick={handleImageClick}>Edit Image</button>
                </div>
              </div>
              <div className={style.DetailsPopupRightSection}>
                <div className={style.FileDetails}>
                  <p>Upload on:{uploadDate}</p>
                  <p>Upload by:{editUserName}</p>
                  <p>File name:{editFileName}</p>
                  <p>File type:{editFileType}</p>
                  <p>File size:{editFileSize}</p>
                </div>
                <div className={style.Discription}>
                  <div className={style.DiscriptionSection}>
                    <div className={style.DiscriptionLeft}>
                      <p>Alternative Text</p>
                    </div>
                    <div className={style.DiscriptionRight}>
                      <textarea
                        value={editAlternativeText}
                        onChange={(e) => {
                          setEditAlternativeText(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className={style.DiscriptionSection}>
                    <div className={style.DiscriptionLeft}>
                      <p>Title</p>
                    </div>
                    <div className={style.DiscriptionRight}>
                      <input
                        value={editTitle}
                        onChange={(e) => {
                          setEditTitle(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className={style.DiscriptionSection}>
                    <div className={style.DiscriptionLeft}>
                      <p>Caption</p>
                    </div>
                    <div className={style.DiscriptionRight}>
                      <textarea
                        value={editCaption}
                        onChange={(e) => {
                          setEditCaption(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className={style.DiscriptionSection}>
                    <div className={style.DiscriptionLeft}>
                      <p>Discription</p>
                    </div>
                    <div className={style.DiscriptionRight}>
                      <textarea
                        value={editDiscription}
                        onChange={(e) => {
                          setEditDiscription(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className={style.DiscriptionSection}>
                    <div className={style.DiscriptionLeft}>
                      <p>File url</p>
                    </div>
                    <div className={style.DiscriptionRight}>
                      <input value={editFile} readOnly />
                    </div>
                  </div>
                  <div className={style.DiscriptionSection}>
                    <div className={style.DiscriptionLeft}></div>
                    <div className={style.DiscriptionRight}>
                      <button>Copy Url to clipboard</button>
                    </div>
                  </div>
                  <div className={style.DiscriptionButton}>
                    <button className={style.Update} onClick={()=>{
                      fileUpdate()
                    }}>Update</button>
                    <button
                      className={style.Cancel}
                      onClick={() => {
                        setDetailsPopup(false);
                      }}
                    >
                      Cancel
                    </button>
                    <IconButton
                        aria-label="delete"
                        color="error"
                        sx={{padding:"0"}}
                       onClick={()=>{
                        setDeletePopup(true)
                       }}
                      >
                        <MdDelete />
                      </IconButton>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
      {deletePopup ? (
        <>
          <div
            onClick={() => {
              setDeletePopup(false);
              
            }}
            className={style.Overlay}
          ></div>
          <div className={style.deletePopup}>
            <div className={style.deleteClose}>
              <MdClose
                onClick={() => {
                  setDeletePopup(false);
                  
                }}
              />
            </div>
            <div className={style.deleteSection}>
              <div className={style.popupText}>
                <p> Are you sure you want to delete ?
                </p>
              </div>
              <div className={style.popupDelete}>
                <button
                  className={style.orgDelete}
                  onClick={() => {
                    filesDelete()
                  }}
                >
                  Delete
                </button>
                <button
                  className={style.orgDeleteCancel}
                  onClick={() => {
                    setDeletePopup(false);
                    
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>


          </div>
        </>
      ) : (
        ""
      )}

    </div>
  );
};

export default Gallery;
