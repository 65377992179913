import {
    LOGIN_FAIL,
    LOGIN_REQUEST,
    LOGIN_SUCCESS,

    PROFILE_FAIL,
    PROFILE_REQUEST,
    PROFILE_SUCCESS,

    ROLE_FAIL,
    ROLE_REQUEST,
    ROLE_SUCCESS,
} from "../constants/user";
import { baseUrl } from "../Components/Url";

export const loginAction = (data) => async (dispatch) => {
    try {
        dispatch({
            type: LOGIN_REQUEST,
        });
        await fetch(`${baseUrl}/account/login/adminpanel`, {
            method: "POST",
            body: JSON.stringify({
                Username: data.username,
                Password: data.password,
            }),
            headers: {
                "Content-Type": "application/json",
            }
        }).then((res) => res.json()).then((data) => {
            dispatch({
                type: LOGIN_SUCCESS,
                payload: data,
            })
            localStorage.setItem("access-token", JSON.stringify(data.data.access_token));
            localStorage.setItem("adminUserId", (data.data.user_id));
            localStorage.setItem("selectedOrgID", (data.data.org_id));
            localStorage.setItem("adminStatus", (data.data.is_superadmin));
            console.log(data.data.access_token);
        });
    }
    catch (error) {
        dispatch({
            type: LOGIN_FAIL,
            payload: error
        })
    }
}
export const UserProfile = () => async (dispatch) => {
    try {
        dispatch({
            type: PROFILE_REQUEST,
        });
        const userId = localStorage.getItem("adminUserId")
        await fetch(`${baseUrl}/user/details`, {
            method: "POST",
            body: JSON.stringify({
                user_id: userId
            }),
            headers: {
                "Content-Type": "application/json",
            }
        }).then((res) => res.json()).then((data) => {
            dispatch({
                type: PROFILE_SUCCESS,
                payload: data,
            })

            console.log(data);
        });
    }
    catch (error) {
        dispatch({
            type: PROFILE_FAIL,
            payload: error
        })
    }
}
export const RoleGet = (data) => async (dispatch) => {
    try {
        dispatch({
            type: ROLE_REQUEST,
        });
        await fetch(`${baseUrl}/role/get/all/admin`, {
            method: "POST",
            body: JSON.stringify({
                org_id: data.orgId,
                club_id: data.clubId
            }),

            headers: {
                "Content-Type": "application/json",
            }
        }).then((res) => res.json()).then((data) => {
            dispatch({
                type: ROLE_SUCCESS,
                payload: data,
            })

            console.log(data);
        });
    }
    catch (error) {
        dispatch({
            type: ROLE_FAIL,
            payload: error
        })
    }
}

