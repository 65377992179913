import { CLUB_FAIL, CLUB_REQUEST, CLUB_SUCCESS } from "../constants/List";
import { baseUrl } from "../Components/Url";


export const ClubGet = (orgId) => async (dispatch) => {
    try {
        dispatch({
            type: CLUB_REQUEST,
        });
        await fetch(`${baseUrl}/club/list`, {
            method: "POST",
            body: JSON.stringify({
                OrganizationID: orgId,
            }),
            headers: {
                "Content-Type": "application/json",
            }
        }).then((res) => res.json()).then((data) => {
            dispatch({
                type: CLUB_SUCCESS,
                payload: data,
            })

            console.log(data);
        });
    }
    catch (error) {
        dispatch({
            type: CLUB_FAIL,
            payload: error
        })
    }
}