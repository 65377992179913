import React, { useEffect, useState } from "react";
import style from "./PricingAction.module.css";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation,useNavigate } from "react-router-dom";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { baseUrl } from "../Url";

const PricingAction = () => {
  const location = useLocation();
  const state = location.state;
  const Module = state?.module;
  console.log(state);
  const Type=state?.type
  const mentorID = state?.mentorid;
  const mentorName = state?.menotorname;
  const batchID = state?.batchid;
  const batchName = state?.batchname;
  const clubid=state?.clubid
  const clubname=state?.clubname
  const coursename=state?.course
  const courseId=state?.courseid
  const pricingID=state?.pricingid
  const headname=state?.name
 const navigate =useNavigate()
  const [featureList, setFeatureList] = useState([]);

  const [orgID, setOrgID] = useState("");
  const [country, setCountry] = useState("");
  const [price, setPrice] = useState("");
  const [currency, setCurrency] = useState("");
  const [uom, setUom] = useState("");
  const [title, setTitle] = useState("");
  const [shortDec, setShortDec] = useState("");
  const [dec, setDec] = useState("");
  const [errors, setErrors] = useState({});
  const [selectedFeatures, setSelectedFeatures] = useState([]);
  const [editCountry, setEditCountry] = useState("");
  const [editPrice, setEditPrice] = useState("");
  const [editCurrency, setEditCurrency] = useState("");
  const [editUom, setEditUom] = useState("");
  const [editTitle, setEditTitle] = useState("");
  const [editShortDec, setEditShortDec] = useState("");
  const [editDec, setEditDec] = useState("");
  const [selectedFeaturesEdit, setSelectedFeaturesEdit] = useState([]);
  const[pricingDetails,setpricingDetais]=useState([])

  async function getFeatures(modulecode) {
    await fetch(`${baseUrl}/fetch/features`, {
      method: "POST",
      body: JSON.stringify({
        ModuleCode: modulecode,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === true) {
          setFeatureList(data.data);
        }
      });
  }

  const validatementorPriceCreate = () => {
    const errors = {};
  
    if (country.trim() === "") {
      errors.country = "Country is required";
    }
  
    if (price.trim() === "") {
      errors.price = "Price is required";
    }
  
    if (currency.trim() === "") {
      errors.currency = "Currency is required";
    }
    if (uom.trim() === "") {
      errors.uom = "UOM is required";
    }
  
    setErrors(errors);
  
    return Object.keys(errors).length === 0;
  };
  //mentor price create
  async function mentorPriceCreate() {
    const bodyData={
      org_id:orgID,
      mentor_id:mentorID,
      mentor_name:mentorName,
      country:country,
      rate:price,
      currency:currency,
      uom:uom,
      title:title,
      description:dec,
      short_des:shortDec,
      features: selectedFeatures.map((featureCode) => {
        const feature = featureList
          .flatMap((module) => module.Features)
          .find((feature) => feature.Featurecode === featureCode);
        return {
          Featurename: feature.Featurename,
          Featurecode: feature.Featurecode,
        };
      }),
      }
      const isValidateMentor=validatementorPriceCreate()
      if(isValidateMentor){
    await fetch(`${baseUrl}/create/mentor/prizing`, {
      method: "POST",
      body: JSON.stringify(bodyData),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if(data.status===true){
          navigate("/pricing")
          setSelectedFeatures([])
          setErrors({})
          
        }
      });
    }
  }
  const validatebatchPriceCreate = () => {
    const errors = {};
  
    if (country.trim() === "") {
      errors.country = "Country is required";
    }
  
    if (price.trim() === "") {
      errors.price = "Price is required";
    }
  
    if (currency.trim() === "") {
      errors.currency = "Currency is required";
    }
    if (uom.trim() === "") {
      errors.uom = "UOM is required";
    }
  
    setErrors(errors);
  
    return Object.keys(errors).length === 0;
  };
  //batch price create
  async function batchPriceCreate() {
    const bodydata={
      org_id:orgID,
      club_id:batchID,
      club_name:batchName,
      country:country,
      rate:price,
      currency:currency,
      uom:uom,
      title:title,
      description:dec,
      short_des:shortDec,
      features: selectedFeatures.map((featureCode) => {
        const feature = featureList
          .flatMap((module) => module.Features)
          .find((feature) => feature.Featurecode === featureCode);
        return {
          Featurename: feature.Featurename,
          Featurecode: feature.Featurecode,
        };
      }),
      }
      const isvalidateBatch=validatebatchPriceCreate()
      if(isvalidateBatch){
  
    await fetch(`${baseUrl}/create/batch/prizing`, {
      method: "POST",
      body: JSON.stringify(bodydata),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if(data.status===true){
          navigate("/pricing")
          setSelectedFeatures([])
          setErrors({})
        }
      });
    }
  }

  const validatecoursePriceCreate = () => {
    const errors = {};
  
    if (country.trim() === "") {
      errors.country = "Country is required";
    }
  
    if (price.trim() === "") {
      errors.price = "Price is required";
    }
  
    if (currency.trim() === "") {
      errors.currency = "Currency is required";
    }
    if (uom.trim() === "") {
      errors.uom = "UOM is required";
    }
    setErrors(errors);
  
    return Object.keys(errors).length === 0;
  };
  //course price create
  async function coursePriceCreate() {
    const body={
        org_id:orgID,
        club_id:clubid,
        club_name:clubname,
        category_id:courseId,
        category_name:coursename,
        country:country,
        rate:price,
        currency:currency,
        uom:uom,
        title:title,
      description:dec,
      short_des:shortDec,
      features: selectedFeatures.map((featureCode) => {
        const feature = featureList
          .flatMap((module) => module.Features)
          .find((feature) => feature.Featurecode === featureCode);
        return {
          Featurename: feature.Featurename,
          Featurecode: feature.Featurecode,
        };
      }),
    }
    const isFormValid = validatecoursePriceCreate();
    if(isFormValid){
    await fetch(`${baseUrl}/create/course/prizing`, {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if(data.status===true){
          navigate("/pricing")
          setSelectedFeatures([])
          setErrors({})
                
        }
      });
    }
  }
   //course pricingdetails
   async function individualCoursePrizing() {
    await fetch(`${baseUrl}/individual/course/prizing/click`, {
      method: "POST",
      body: JSON.stringify({
        coursePrizings_id:pricingID
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if(data.status===true){
         setpricingDetais(data.data)
        }
      });
  }
  //mentor pricingdetails
  async function individualMentorPrizing() {
    await fetch(`${baseUrl}/individual/mentor/prizing/click`, {
      method: "POST",
      body: JSON.stringify({
        MentorPrizings_id:pricingID
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if(data.status===true){
         setpricingDetais(data.data)
        }
      });
  }
  //batch pricingdetails
  async function individualBatchPrizing() {
    await fetch(`${baseUrl}/individual/batch/prizing/click`, {
      method: "POST",
      body: JSON.stringify({
        clubPrizings_id:pricingID
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if(data.status===true){
         setpricingDetais(data.data)
        }
      });
  }
  const validatecoursePriceEdit = () => {
    const errors = {};
  
    if (editCountry.trim() === "") {
      errors.editCountry = "Country is required";
    }
  
    if (editPrice.trim() === "") {
      errors.editPrice = "Price is required";
    }
  
    if (editCurrency.trim() === "") {
      errors.editCurrency = "Currency is required";
    }
    if (editUom.trim() === "") {
      errors.editUom = "UOM is required";
    }
  
    setErrors(errors);
  
    return Object.keys(errors).length === 0;
  };
  //course price edit
  async function coursePriceEdit() {
    const body={
      coursePrizings_id:pricingID,
      country:editCountry,
      rate:editPrice,
      currency:editCurrency,
      uom:editUom,
      title:editTitle,
      description:editDec,
      short_des:editShortDec,
      features: selectedFeaturesEdit.map((featureCode) => {
        const feature = featureList
          .flatMap((module) => module.Features)
          .find((feature) => feature.Featurecode === featureCode);
        return {
          Featurename: feature.Featurename,
          Featurecode: feature.Featurecode,
        };
      }),
    }
      const isFormValid = validatecoursePriceEdit();
      if(isFormValid){
  
      
    await fetch(`${baseUrl}/edit/course/prizing`, {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if(data.status===true){
          navigate("/pricing",{
            state:{
              tab:0
            }
          })
          setSelectedFeaturesEdit([])
          setErrors({})
        }
      });
    }
  }

  const validatementorPriceEdit = () => {
    const errors = {};
  
    if (editCountry.trim() === "") {
      errors.editCountry = "Country is required";
    }
  
    if (editPrice.trim() === "") {
      errors.editPrice = "Price is required";
    }
  
    if (editCurrency.trim() === "") {
      errors.editCurrency = "Currency is required";
    }
    if (editUom.trim() === "") {
      errors.editUom = "UOM is required";
    }
  
    setErrors(errors);
  
    return Object.keys(errors).length === 0;
  };
  //mentor price edit
  async function mentorPriceEdit() {
    const bodyData={
      MentorPrizings_id:pricingID,
      country:editCountry,
      rate:editPrice,
      currency:editCurrency,
      uom:editUom,
      title:editTitle,
      description:editDec,
      short_des:editShortDec,
      features: selectedFeaturesEdit.map((featureCode) => {
        const feature = featureList
          .flatMap((module) => module.Features)
          .find((feature) => feature.Featurecode === featureCode);
        return {
          Featurename: feature.Featurename,
          Featurecode: feature.Featurecode,
        };
      }),
      }
      const isValidateMentor=validatementorPriceEdit()
      if(isValidateMentor){
  
    await fetch(`${baseUrl}/edit/mentor/prizing`, {
      method: "POST",
      body: JSON.stringify(bodyData),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if(data.status===true){
          navigate("/pricing",{
            state:{
              tab:1
            }
          })
          setSelectedFeaturesEdit([])
          setErrors({})
        }
      });
    }
  }
  const validatebatchPriceEdit = () => {
    const errors = {};
  
    if (editCountry.trim() === "") {
      errors.editCountry = "Country is required";
    }
  
    if (editPrice.trim() === "") {
      errors.editPrice = "Price is required";
    }
  
    if (editCurrency.trim() === "") {
      errors.editCurrency = "Currency is required";
    }
    if (editUom.trim() === "") {
      errors.editUom = "UOM is required";
    }
  
    setErrors(errors);
  
    return Object.keys(errors).length === 0;
  };
  //batch price edit
  async function batchPriceEdit() {
  const bodydata={
    clubPrizings_id:pricingID,
    country:editCountry,
    rate:editPrice,
    currency:editCurrency,
    uom:editUom,
    title:editTitle,
    description:editDec,
    short_des:editShortDec,
    features: selectedFeaturesEdit.map((featureCode) => {
      const feature = featureList
        .flatMap((module) => module.Features)
        .find((feature) => feature.Featurecode === featureCode);
      return {
        Featurename: feature.Featurename,
        Featurecode: feature.Featurecode,
      };
    }),
    }
    const isvalidateBatch=validatebatchPriceEdit()
    if(isvalidateBatch){
    await fetch(`${baseUrl}/edit/batch/prizing`, {
      method: "POST",
      body: JSON.stringify(bodydata),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if(data.status===true){
          navigate("/pricing",{
            state:{
              tab:2            }
          })
          setSelectedFeaturesEdit([])
          setErrors({})
        }
      });
    }
  }

  const { profile } = useSelector((state) => state.profileData);

  const handleEditorChange = (event, editor) => {
    const data = editor.getData();
    setDec(data);
  };
  const handleEditEditorChange = (event, editor) => {
    const data = editor.getData();
    setEditDec(data);
  };

  const handleFeatureChange = (featureCode) => {
    // Toggle the selected feature
    setSelectedFeatures((prevSelectedFeatures) => {
      if (prevSelectedFeatures.includes(featureCode)) {
        return prevSelectedFeatures.filter((code) => code !== featureCode);
      } else {
        return [...prevSelectedFeatures, featureCode];
      }
    });
  };
  const handleEditFeatureChange = (featureCode) => {
    // Toggle the selected feature
    setSelectedFeaturesEdit((prevSelectedFeatures) => {
      if (prevSelectedFeatures.includes(featureCode)) {
        return prevSelectedFeatures.filter((code) => code !== featureCode);
      } else {
        return [...prevSelectedFeatures, featureCode];
      }
    });
  };
  useEffect(() => {
    if(Module==="Mentor"){
      getFeatures("MENTOR");
    }
    else if(Module==="Batch"){
      getFeatures("BATCH");
    }
    else{
      getFeatures("COURSE");
    }
  }, [Module]);

  useEffect(()=>{
    if(Module==="Mentor"){
      individualMentorPrizing()
    }
    else if(Module==="Batch"){
      individualBatchPrizing()
    }
    else{
      individualCoursePrizing()
    }
  },[Type])

  useEffect(()=>{
    if(pricingDetails.length>0){
      setEditCountry(pricingDetails[0].country)
      setEditCurrency(pricingDetails[0].currency)
      setEditDec(pricingDetails[0].description?pricingDetails[0].description:'')
      setEditPrice(pricingDetails[0].rate)
      setEditShortDec(pricingDetails[0].short_des)
      setEditTitle(pricingDetails[0].title)
      setEditUom(pricingDetails[0].uom)
      const SelectedFeatureCodes = pricingDetails[0].features?pricingDetails[0].features.map(feature => feature.Featurecode):[];
                                  setSelectedFeaturesEdit(SelectedFeatureCodes)
    }

  },[pricingDetails])


  useEffect(() => {
    setOrgID(profile?.org_id);
  }, [profile]);
  return (
    <div className={style.Container}>
      {Type==="create"?
      <>
        <div className={style.Header}>
          <div className={style.HeaderText}>
            {Module==="Mentor"?
            <p>Manage Pricing For {headname}</p>
            :Module==="Batch"?
            <p>Batch Pricing Create</p>
            : <p>Manage Pricing For {headname}</p>
             }
          </div>
        </div>
        <div className={style.CreateSection}>
          <div className={style.InputContainer}>
            <div className={style.Label}>Price Title </div>:&nbsp;
            <input 
            value={title}
            onChange={(e)=>{
              setTitle(e.target.value)
            }}
             autoComplete="off" placeholder="Price Title" />
          </div>
          <div className={style.InputContainer}>
            <div className={style.Label}>Short Description </div>:&nbsp;
            <textarea 
            value={shortDec}
            onChange={(e)=>{
              setShortDec(e.target.value)
            }}
            autoComplete="off" placeholder="Short Description" />
          </div>
          <div className={style.InputContainer}>
            <div className={style.Label}>Description </div>:&nbsp;
            <div className={style.editSection}>
              <CKEditor
                editor={ClassicEditor}
                data={dec}
                onChange={handleEditorChange}
                onReady={(editor) => {
                  editor.editing.view.change((writer) => {
                    writer.setStyle(
                      "height",
                      "150px",
                      editor.editing.view.document.getRoot()
                    );
                  });
                }}
                config={{
                  placeholder: "Enter your Description...",
                  toolbar: [
                    "heading",
                    "|",
                    "bold",
                    "italic",
                    "link",
                    "bulletedList",
                    "numberedList",
                    "blockQuote",
                  ],
                  alignment: {
                    options: ["left", "center", "right"],
                  },
                }}
              />
            </div>
          </div>
          <div className={style.InputContainer}>
            <div className={style.Label}>Country </div>:&nbsp;
            <FormControl sx={{ width: "25%", margin: "0 0.25rem" }}>
              <InputLabel id="demo-simple-select-label">
                Select Country
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Select Club/Batch"
                onChange={(e) => {
                  const country = e.target.value;
                  setCountry(country)
                }}
              >
                <MenuItem value="India"> India</MenuItem>
                <MenuItem value="USA">USA</MenuItem>
                <MenuItem value="UAE">UAE</MenuItem>
              </Select>
            </FormControl>
          
          </div>
          <div className={style.InputContainer}>
            <div className={style.Label}>Rate </div>:&nbsp;
            <input 
            value={price}
            onChange={(e)=>{
              setPrice(e.target.value)
            }}
            autoComplete="off" placeholder="Rate" />
          </div>
          <div className={style.InputContainer}>
            <div className={style.Label}>Currency </div>:&nbsp;
            <FormControl sx={{ width: "25%", margin: "0 0.25rem" }}>
              <InputLabel id="demo-simple-select-label">
                Select Currency
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Select Club/Batch"
                onChange={(e) => {
                  const currency = e.target.value;
                  setCurrency(currency)
                }}
              >
                <MenuItem  value="INR"> INR</MenuItem>
                <MenuItem value="USD">USD</MenuItem>
                <MenuItem value="AED">AED</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className={style.InputContainer}>
            <div className={style.Label}>UOM </div>:&nbsp;
            <FormControl sx={{ width: "25%", margin: "0 0.25rem" }}>
              <InputLabel id="demo-simple-select-label">
                Select UOM
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Select Club/Batch"
                onChange={(e) => {
                  const Uom = e.target.value;
                  setUom(Uom)
                }}
              >
                <MenuItem value="Minutes">Minutes</MenuItem>
              <MenuItem value="Hours">Hours</MenuItem>
              <MenuItem value="Days">Days</MenuItem>
              <MenuItem value="Week">Week</MenuItem>
              <MenuItem value="Month">Month</MenuItem>
              <MenuItem value="Year">Year</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className={style.checkboxContainer}>
            <label className={style.checkboxLabel}>Feature List</label>:
            <div className={style.checkboxGrid}>
              {featureList.map((module) =>
                module.Features.map((feature) => (
                  <div key={feature.Featurecode} className={style.checkboxItem}>
                    <input
                      type="checkbox"
                      id={feature.Featurecode}
                      name={feature.Featurecode}
                      className={style.checkboxInput}
                      checked={selectedFeatures.includes(feature.Featurecode)}
                       onChange={(e) => handleFeatureChange(feature.Featurecode)}
                    />
                    <label
                      className={style.checkboxInputLabel}
                      htmlFor={feature.Featurecode}
                    >
                      {feature.Featurename}
                    </label>
                  </div>
                ))
              )}
            </div>
          </div>
          <div className={style.errorMessages}>
                {Object.values(errors).map((error, index) => (
                  <div key={index} className={style.errorValue}>
                    <p>{error}</p>
                  </div>
                ))}
              </div>
          <div className={style.Buttons}>
            <button className={style.create} onClick={()=>{
              if(Module==="Mentor"){
                mentorPriceCreate()
              }
              else if(Module==='Batch'){
                batchPriceCreate()
              }
              else{
                coursePriceCreate()
              }
             
            }}>Create</button>
            <button className={style.cancel} onClick={()=>{
              navigate("/pricing")
            }}
            >Cancel</button>
          </div>
        </div>
      </>
      :
      <>
        <div className={style.Header}>
          <div className={style.HeaderText}>
            {Module==="Mentor"?
            <p>Manage Pricing For {headname}</p>
            :Module==="Batch"?
            <p>Batch Pricing Edit</p>
            : <p>Manage Pricing For {headname}</p>
             }
          </div>
        </div>
        <div className={style.CreateSection}>
          <div className={style.InputContainer}>
            <div className={style.Label}>Price Title </div>:&nbsp;
            <input 
            value={editTitle}
            onChange={(e)=>{
              setEditTitle(e.target.value)
            }}
             autoComplete="off" placeholder="Price Title" />
          </div>
          <div className={style.InputContainer}>
            <div className={style.Label}>Short Description </div>:&nbsp;
            <textarea 
            value={editShortDec}
            onChange={(e)=>{
              setEditShortDec(e.target.value)
            }}
            autoComplete="off" placeholder="Short Description" />
          </div>
          <div className={style.InputContainer}>
            <div className={style.Label}>Description </div>:&nbsp;
            <div className={style.editSection}>
              <CKEditor
                editor={ClassicEditor}
                data={editDec}
                onChange={handleEditEditorChange}
                onReady={(editor) => {
                  editor.editing.view.change((writer) => {
                    writer.setStyle(
                      "height",
                      "150px",
                      editor.editing.view.document.getRoot()
                    );
                  });
                }}
                config={{
                  placeholder: "Enter your Description...",
                  toolbar: [
                    "heading",
                    "|",
                    "bold",
                    "italic",
                    "link",
                    "bulletedList",
                    "numberedList",
                    "blockQuote",
                  ],
                  alignment: {
                    options: ["left", "center", "right"],
                  },
                }}
              />
            </div>
          </div>
          <div className={style.InputContainer}>
            <div className={style.Label}>Country </div>:&nbsp;
            <FormControl sx={{ width: "25%", margin: "0 0.25rem" }}>
              <InputLabel id="demo-simple-select-label">
                Select Country
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Select Club/Batch"
                value={editCountry}
                onChange={(e) => {
                  const country = e.target.value;
                  setEditCountry(country)
                }}
              >
                <MenuItem value="India"> India</MenuItem>
                <MenuItem value="USA">USA</MenuItem>
                <MenuItem value="UAE">UAE</MenuItem>
              </Select>
            </FormControl>
            
          </div>
          <div className={style.InputContainer}>
            <div className={style.Label}>Rate </div>:&nbsp;
            <input 
            value={editPrice}
            onChange={(e)=>{
              setEditPrice(e.target.value)
            }}
            autoComplete="off" placeholder="Rate" />
          </div>
          <div className={style.InputContainer}>
            <div className={style.Label}>Currency </div>:&nbsp;
            <FormControl sx={{ width: "25%", margin: "0 0.25rem" }}>
              <InputLabel id="demo-simple-select-label">
                Select Currency
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Select Club/Batch"
                value={editCurrency}
              onChange={(e) => {
                const currency = e.target.value;
                setEditCurrency(currency)
              }}
              >
                <MenuItem  value="INR"> INR</MenuItem>
                <MenuItem value="USD">USD</MenuItem>
                <MenuItem value="AED">AED</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className={style.InputContainer}>
            <div className={style.Label}>UOM </div>:&nbsp;
            <FormControl sx={{ width: "25%", margin: "0 0.25rem" }}>
              <InputLabel id="demo-simple-select-label">
                Select UOM
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Select Club/Batch"
                value={editUom}
              onChange={(e) => {
                const Uom = e.target.value;
                setEditUom(Uom)
              }}
              >
                <MenuItem value="Minutes">Minutes</MenuItem>
              <MenuItem value="Hours">Hours</MenuItem>
              <MenuItem value="Days">Days</MenuItem>
              <MenuItem value="Week">Week</MenuItem>
              <MenuItem value="Month">Month</MenuItem>
              <MenuItem value="Year">Year</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className={style.checkboxContainer}>
            <label className={style.checkboxLabel}>Feature List</label>:
            <div className={style.checkboxGrid}>
              {featureList.map((module) =>
                module.Features.map((feature) => (
                  <div key={feature.Featurecode} className={style.checkboxItem}>
                    <input
                      type="checkbox"
                      id={feature.Featurecode}
                      name={feature.Featurecode}
                      className={style.checkboxInput}
                      checked={selectedFeaturesEdit.includes(feature.Featurecode)}
                       onChange={(e) => handleEditFeatureChange(feature.Featurecode)}
                    />
                    <label
                      className={style.checkboxInputLabel}
                      htmlFor={feature.Featurecode}
                    >
                      {feature.Featurename}
                    </label>
                  </div>
                ))
              )}
            </div>
          </div>
          <div className={style.errorMessages}>
                {Object.values(errors).map((error, index) => (
                  <div key={index} className={style.errorValue}>
                    <p>{error}</p>
                  </div>
                ))}
              </div>
          <div className={style.Buttons}>
            <button className={style.create} onClick={()=>{
              if(Module==="Mentor"){
               mentorPriceEdit()
              }
              else if(Module==='Batch'){
               batchPriceEdit()
              }
              else{
                coursePriceEdit()
              }
             
            }}>Update</button>
            <button className={style.cancel} onClick={()=>{
              navigate("/pricing")
            }}
            >Cancel</button>
          </div>
        </div>
      </>

      }
    </div>
  );
};

export default PricingAction;
