import React, { useEffect, useState } from "react";
import style from "./Invoice.module.css";
import { AiOutlineClose } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate ,useLocation} from "react-router-dom";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { baseUrl } from "../Url";

const Invoice = () => {
  const navigate = useNavigate();
  const location=useLocation()
  const locationState=location.state
  const InvoiceNumber=locationState?.invoice_no
  const[invoiceEdit,setInvoiceEdit]=useState(false)
    useEffect(()=>{
    setInvoiceEdit(locationState?.invoiceEdit)
  },[location])
  const [userList, setUserList] = useState([]);
  const [orgID, setOrgID] = useState("");
  const [pincode, setPincode] = useState("");
  const [country, setCountry] = useState("");
  const [address, setAddress] = useState("");
  const [total, setTotal] = useState("");
  const [taxAmount, setTaxAmount] = useState("");
  const [netAmount, setNetAmount] = useState("");
  const [discount, setDiscount] = useState("");
  const [state, setState] = useState("");
  const [editPincode, setEditPincode] = useState("");
  const [editCountry, setEditCountry] = useState("");
  const [editAddress, setEditAddress] = useState("");
  const [editTaxAmount, setEditTaxAmount] = useState("");
  const [editNetAmount, setEditNetAmount] = useState("");
  const [editDiscount, setEditDiscount] = useState("");
  const[editState,setEditState]=useState('')
  const[editTotal,setEditTotal]=useState('')
  const[editDate,setEditDate]=useState('')
  const [selectedUser, setSelectedUser] = useState("");
  const [selectedUserName, setSelectedUserName] = useState("");
  const [errors, setErrors] = useState({});
  const[itemName,setItemName]=useState([])
  const[clubSelect,setClubSelect]=useState(false)
  const[clubList,setClubList]=useState([])
  const[selectedClub,setSelectedClub]=useState('')
  const[selectedCategory,setSelectedCategory]=useState('')
  const[selectedItemName,setSelecteditemName]=useState('')
  const[selectedItemId,setSelecteditemId]=useState('')
  const[priceDetails,setPriceDetails]=useState({});
  const[date,setDate]=useState("")
  const[disabledAction,setDisabledAction]=useState('')
  const[invoiceIndividual,setInvoiceIndividual]=useState({})
  const [items, setItems] = useState([
    {
      itemname: "",
      rate: "",
      qty: "",
      total: "",
      discount: "",
      net_total: "",
      uom: "",
    },
  ]);
  const [editItems, setEditItems] = useState([
    {
      itemname: '',
      rate: '',
      qty: '',
      total: '',
      discount: '',
      net_total: '',
      uom: ''
    }
  ]);
  const { profile } = useSelector((state) => state.profileData);

  useEffect(() => {
    setOrgID(profile?.org_id);
    setLoginUserID(profile?._id);
  }, [profile]);
  const [loginUserID, setLoginUserID] = useState("");
  async function getUsers() {
    await fetch(`${baseUrl}/fetch/org/customers`, {
      method: "POST",
      body: JSON.stringify({
        org_id: orgID,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === true) {
          setUserList(data.data);
        }
      });
  }
  //get Items
  async function getItemName(category) {
    await fetch(`${baseUrl}/get/category/selection/invoice`, {
      method: "POST",
      body: JSON.stringify({
        org_id:orgID,
        selection:category
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if(data.status===true){
          if(data.description==="Please_Select_The_Club"){
            setClubSelect(true)
            getClubs()
          }
          else{
            setItemName(data.data)
          }
          
        }
      });
  }

  //clublist
async function getClubs() {
  await fetch(`${baseUrl}/get/batches`, {
    method: "POST",
    body: JSON.stringify({
      org_id:orgID
    }),
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((res) => res.json())
    .then((data) => {
      console.log(data);
      if(data.status===true){
        setClubList(data.data)
      }
    });
}
async function getCourses(clubid) {
  await fetch(`${baseUrl}/get/courses/new`, {
    method: "POST",
    body: JSON.stringify({
      club_id:clubid
    }),
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((res) => res.json())
    .then((data) => {
      console.log(data);
      if(data.status===true){
       setItemName(data.data)
      }
    });
}
//pricing details
async function getPricing(id) {
  await fetch(`${baseUrl}/fetch/PrizingofCategory`, {
    method: "POST",
    body: JSON.stringify({
      org_id:orgID,
      selection:selectedCategory,
      id:id
    }),
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((res) => res.json())
    .then((data) => {
      console.log(data);
      if(data.status===true){
        setPriceDetails(data)
        if (data.data.length > 0) {
          const matchingItems = data.data.filter((apiItem) => apiItem.country === country);
            const updatedItems = matchingItems.length > 0 ? matchingItems.map((apiItem) => {
              console.log(apiItem);
                let itemName = "";
   
                if (selectedCategory === "Course") {
                   itemName = apiItem.category_name; 
                } else if (selectedCategory === "Mentor") {
                itemName = apiItem.mentor_name; 
                   } else if (selectedCategory === "Batch") {
                      itemName = apiItem.club_name; 
                         }
   
                   return {
                     itemname: itemName || "",
                     rate: apiItem.rate || "",
                     uom: apiItem.uom || "",
                     qty: "", 
                     total: "",
                     discount: "",
                     net_total: "",
                   };
                 }) : data.data.filter(apiItem => apiItem.country === 'India')
           .map((apiItem) => {
           console.log(apiItem);
             let itemName = "";

             if (selectedCategory === "Course") {
                itemName = apiItem.category_name; 
             } else if (selectedCategory === "Mentor") {
             itemName = apiItem.mentor_name; 
                } else if (selectedCategory === "Batch") {
                   itemName = apiItem.club_name; 
                      }

                return {
                  itemname: itemName || "",
                  rate: apiItem.rate || "",
                  uom: apiItem.uom || "",
                  qty: "", 
                  total: "",
                  discount: "",
                  net_total: "",
                };
              });

              // Set the items state with the updatedItems
              if (items.length === 1 && Object.values(items[0]).every((val) => val === "")) {
                // Replace the empty row with the new items
                console.log(updatedItems);
                setItems(updatedItems);
              } else {
                // Merge the existing items with the new items from the API
                console.log(updatedItems);
                setItems((prevItems) => [...prevItems, ...updatedItems]);
              }        
            }
        else{
          const emptyData=[{
            itemname: "",
            rate: "",
            qty: "",
            total: "",
            discount: "",
            net_total: "",
            uom: "",
          },]
          if (items.length === 1 && Object.values(items[0]).every(val => val === "")) {
            // Replace the empty row with the new items
            setItems(emptyData);
          } else {
            // Merge the existing items with the new items from the API
            setItems((prevItems) => [...prevItems, ...emptyData]);
          }
        }
        
      }
    });
}
//for edit price
async function getPricingEdit(id) {
  await fetch(`${baseUrl}/fetch/PrizingofCategory`, {
    method: "POST",
    body: JSON.stringify({
      org_id:orgID,
      selection:selectedCategory,
      id:id
    }),
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((res) => res.json())
    .then((data) => {
      console.log(data);
      if(data.status===true){
        if (data.data.length > 0) {
          const matchingItems = data.data.filter((apiItem) => apiItem.country === editCountry);
            const updatedItems = matchingItems.length > 0 ? matchingItems.map((apiItem) => {
              console.log(apiItem);
                let itemName = "";
   
                if (selectedCategory === "Course") {
                   itemName = apiItem.category_name; 
                } else if (selectedCategory === "Mentor") {
                itemName = apiItem.mentor_name; 
                   } else if (selectedCategory === "Batch") {
                      itemName = apiItem.club_name; 
                         }
   
                   return {
                     itemname: itemName || "",
                     rate: apiItem.rate || "",
                     uom: apiItem.uom || "",
                     qty: "", 
                     total: "",
                     discount: "",
                     net_total: "",
                   };
                 }) : data.data.filter(apiItem => apiItem.country === 'India')
           .map((apiItem) => {
           console.log(apiItem);
             let itemName = "";

             if (selectedCategory === "Course") {
                itemName = apiItem.category_name; 
             } else if (selectedCategory === "Mentor") {
             itemName = apiItem.mentor_name; 
                } else if (selectedCategory === "Batch") {
                   itemName = apiItem.club_name; 
                      }

                return {
                  itemname: itemName || "",
                  rate: apiItem.rate || "",
                  uom: apiItem.uom || "",
                  qty: "", 
                  total: "",
                  discount: "",
                  net_total: "",
                };
              });

              // Set the items state with the updatedItems
              if (editItems.length === 1 && Object.values(editItems[0]).every((val) => val === "")) {
                // Replace the empty row with the new items
                console.log(updatedItems);
                setEditItems(updatedItems);
              } else {
                // Merge the existing items with the new items from the API
                console.log(updatedItems);
                setEditItems((prevItems) => [...prevItems, ...updatedItems]);
              }        
            }
        else{
          const emptyData=[{
            itemname: "",
            rate: "",
            qty: "",
            total: "",
            discount: "",
            net_total: "",
            uom: "",
          },]
          if (editItems.length === 1 && Object.values(editItems[0]).every(val => val === "")) {
            // Replace the empty row with the new items
            setEditItems(emptyData);
          } else {
            // Merge the existing items with the new items from the API
            setEditItems((prevItems) => [...prevItems, ...emptyData]);
          }
        }
        
      }
    });
}
useEffect(() => {
  // Disable or enable elements based on the presence of data
  const isDataEntered =
      country.trim() !== "" &&
      state.trim() !== "" &&
      pincode.trim() !== "" &&
      address.trim() !== "" &&
      selectedUser.trim() !== "";
setDisabledAction(isDataEntered)
}, [country, state, pincode, address, selectedUser]);

  const createData = {
    user_name: selectedUserName,
    user_id: selectedUser,
    org_id: orgID,
    date:date,
    state:state,
    billingaddress: [
      {
        pin: pincode,
        country: country,
        address: address,
      },
    ],
    total:total,
    taxableamount: taxAmount,
    net_amount: netAmount,
    discount: discount,
    created_by: loginUserID,
    items: items,
  };

  const validateCreateInvoice = () => {
    const errors = {};

    if (pincode.trim() === "") {
      errors.pincode = "Pincode is required";
    }

    if (country.trim() === "") {
      errors.country = "Country is required";
    }
    if (state.trim() === "") {
      errors.state = "Country is required";
    }

    if (address.trim() === "") {
      errors.address = "Address is required";
    }
    if (taxAmount.trim() === "") {
      errors.taxAmount = "TaxAmount is required";
    }
    if (netAmount.trim() === "") {
      errors.netAmount = "NetAmount is required";
    }
    if (discount.trim() === "") {
      errors.discount = "Discount is required";
    }
    if (total.trim() === "") {
      errors.total = "Total is required";
    }
    if (date.trim() === "") {
      errors.date = "Date is required";
    }
    if (selectedUserName.trim() === "") {
      errors.selectedUserName = "Select one user";
    }
    if (selectedUser.trim() === "") {
      errors.selectedUser = "Select one user";
    }
    if (
      items.some((item) =>
        Object.values(item).some((value) => value.trim() === "")
      )
    ) {
      errors.items = "All item fields are required";
    }

    setErrors(errors);

    return Object.keys(errors).length === 0;
  };
  async function createInvoice() {
    const isValidate = validateCreateInvoice();
    if (isValidate) {
      await fetch(
        `${baseUrl}/create/customer/invoice`,
        {
          method: "POST",
          body: JSON.stringify(createData),
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
        .then((res) => res.json())
        .then((data) => {
          console.log(data);
          if (data.status === true) {
            setPincode("");
            setCountry("");
            setAddress("");
            setDiscount("");
            setTaxAmount("");
            setNetAmount("");
            setSelectedUser("");
            setSelectedUserName("");
            setItems([
              {
                itemname: "",
                rate: "",
                qty: "",
                total: "",
                discount: "",
                net_total: "",
                uom: "",
              },
            ]);
            navigate("/billing");
          }
        });
    }
  }
 //invoice individual
  async function getIndividualInvoices(id) {
    await fetch(`${baseUrl}/fetch/individual/invoices`, {
      method: "POST",
      body: JSON.stringify({
        invoice_number:id
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if(data.status===true){
          setInvoiceIndividual(data.data[0])
        }
      });
  }

  const editData={
    invoice_number:InvoiceNumber,
    billingaddress:[{
      pin:editPincode,
      country:editCountry,
      address:editAddress
    }],
    total:editTotal,
    date:editDate,
    state:editState,
    taxableamount:editTaxAmount,
    net_amount:editNetAmount,
    discount:editDiscount,
    edited_by:loginUserID,
    items:editItems
  }
  const validateEditInvoice = () => {
    const errors = {};
  
    if (editPincode.trim() === "") {
      errors.editPincode = "Pincode is required";
    }
  
    if (editCountry.trim() === "") {
      errors.editCountry = "Country is required";
    }
    if (editState.trim() === "") {
      errors.editState = "Country is required";
    }
    if (editAddress.trim() === "") {
      errors.editAddress = "Address is required";
    }
    if (editTaxAmount.trim() === "") {
      errors.editTaxAmount = "TaxAmount is required";
    }
    if (editNetAmount.trim() === "") {
      errors.editNetAmount = "NetAmount is required";
    }
    if (editTotal.trim() === "") {
      errors.editTotal = "Total is required";
    }
    if (editDate.trim() === "") {
      errors.editDate = "Date is required";
    }
    if (editDiscount.trim() === "") {
      errors.editDiscount = "Discount is required";
    }
    if (editItems.some(item => Object.values(item).some(value => value.trim() === ""))) {
      errors.editItems = "All item fields are required";
    }
  
    setErrors(errors);
  
    return Object.keys(errors).length === 0;
  };
  async function editInvoice() {
    const isValidate=validateEditInvoice()
    if(isValidate){
    await fetch(`${baseUrl}/edit/customer/invoice`, {
      method: "POST",
      body: JSON.stringify(editData),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if(data.status===true){
         setEditPincode('')
         setEditCountry('')
         setEditAddress('')
         setEditDiscount('')
         setEditTaxAmount('')
         setEditNetAmount('')
         setEditItems([
         {
           itemname: '',
           rate: '',
           qty: '',
           total: '',
           discount: '',
           net_total: '',
           uom: ''
           }
           ])
        }
        navigate("/billing");
      });
    }
  }


  const handleItemChange = (index, field, value) => {
    const updatedItems = [...items];
    updatedItems[index][field] = value;
    // Check if the changed field is either 'rate' or 'qty'
  if (field === 'rate' || field === 'qty') {
    const rate = parseFloat(updatedItems[index]['rate']) || 0;
    const qty = parseFloat(updatedItems[index]['qty']) || 0;

    // Calculate the total as the multiplication of rate and qty
    updatedItems[index]['total'] = (rate * qty).toFixed(2);
    const total = parseFloat(updatedItems[index]['total']) || 0;
    const discount = parseFloat(updatedItems[index]['discount']) || 0;
    updatedItems[index]['net_total'] = (total - discount).toFixed(2);
  }
  if (field === 'total' || field === 'discount') {
    const total = parseFloat(updatedItems[index]['total']) || 0;
    const discount = parseFloat(updatedItems[index]['discount']) || 0;

    // Calculate the net_total as the difference between total and discount
    updatedItems[index]['net_total'] = (total - discount).toFixed(2);
  }
    setItems(updatedItems);
    const sumNetTotal = updatedItems.reduce((sum, item) => sum + parseFloat(item.net_total || 0), 0);
  // Set the total with the calculated sum
  setTotal(sumNetTotal.toFixed(2));
  updateNetAmount(taxAmount, discount, items);
  };
  
  const addNewItem = () => {
    setItems([
      ...items,
      {
        itemname: "",
        rate: "",
        qty: "",
        total: "",
        discount: "",
        net_total: "",
        uom: "",
      },
    ]);
  };
  const removeItem = (index) => {
    const updatedItems = [...items];
    updatedItems.splice(index, 1);
    setItems(updatedItems);
  };

  const handleTaxAmountChange = (e) => {
    const updatedTaxAmount = e.target.value;
    setTaxAmount(updatedTaxAmount);
    updateNetAmount(updatedTaxAmount, discount, items);
  };
  
  // Separate function to handle discount changes
  const handleDiscountChange = (e) => {
    const updatedDiscount = e.target.value;
    setDiscount(updatedDiscount);
    updateNetAmount(taxAmount, updatedDiscount, items);
  };
  
  // Function to update netAmount based on the formula: total + taxAmount - discount
  const updateNetAmount = (updatedTaxAmount, updatedDiscount, updatedItems) => {
    const discountValue = parseFloat(updatedDiscount) || 0;
    const taxAmountValue = parseFloat(updatedTaxAmount) || 0;
  
    // Calculate the sum of net_total from all items
    const sumNetTotal = updatedItems.reduce((sum, item) => sum + parseFloat(item.net_total || 0), 0);
  
    // Set the total with the calculated sum
    setTotal(sumNetTotal.toFixed(2));
  
    // Calculate the netAmount based on the formula: total + taxAmount - discount
    const calculatedNetAmount = sumNetTotal + taxAmountValue - discountValue;
    setNetAmount(calculatedNetAmount.toFixed(2));
  };
  useEffect(() => {
    // This useEffect will run once when the component mounts
    const currentDate = new Date();
    
    // Format the date as per your requirement
    const formattedDate = `${currentDate.getFullYear()}-${(currentDate.getMonth() + 1).toString().padStart(2, '0')}-${currentDate.getDate().toString().padStart(2, '0')}`;
    
    // Set the formatted date to the state
    setDate(formattedDate);
  }, []); 

  const handleEditItemChange = (index, field, value) => {
    const updatedItems = [...editItems];
    updatedItems[index][field] = value;
    if (field === 'rate' || field === 'qty') {
      const rate = parseFloat(updatedItems[index]['rate']) || 0;
      const qty = parseFloat(updatedItems[index]['qty']) || 0;
  
      // Calculate the total as the multiplication of rate and qty
      updatedItems[index]['total'] = (rate * qty).toFixed(2);
      const total = parseFloat(updatedItems[index]['total']) || 0;
      const discount = parseFloat(updatedItems[index]['discount']) || 0;
      updatedItems[index]['net_total'] = (total - discount).toFixed(2);
    }
    if (field === 'total' || field === 'discount') {
      const total = parseFloat(updatedItems[index]['total']) || 0;
      const discount = parseFloat(updatedItems[index]['discount']) || 0;
  
      // Calculate the net_total as the difference between total and discount
      updatedItems[index]['net_total'] = (total - discount).toFixed(2);
    }
      setEditItems(updatedItems);
      const sumNetTotal = updatedItems.reduce((sum, item) => sum + parseFloat(item.net_total || 0), 0);
    // Set the total with the calculated sum
    setEditTotal(sumNetTotal.toFixed(2));
    updateEditNetAmount(editTaxAmount, editDiscount, editItems);
  };
  const handleEditTaxAmountChange = (e) => {
    const updatedTaxAmount = e.target.value;
    setEditTaxAmount(updatedTaxAmount);
    updateEditNetAmount(editTaxAmount, editDiscount, editItems);
  };
  
  // Separate function to handle discount changes
  const handleEditDiscountChange = (e) => {
    const updatedDiscount = e.target.value;
    setEditDiscount(updatedDiscount);
    updateEditNetAmount(editTaxAmount, editDiscount, editItems);
  };
  
  // Function to update netAmount based on the formula: total + taxAmount - discount
  const updateEditNetAmount = (updatedTaxAmount, updatedDiscount, updatedItems) => {
    const discountValue = parseFloat(updatedDiscount) || 0;
    const taxAmountValue = parseFloat(updatedTaxAmount) || 0;
  
    // Calculate the sum of net_total from all items
    const sumNetTotal = updatedItems.reduce((sum, item) => sum + parseFloat(item.net_total || 0), 0);
  
    // Set the total with the calculated sum
    setEditTotal(sumNetTotal.toFixed(2));
  
    // Calculate the netAmount based on the formula: total + taxAmount - discount
    const calculatedNetAmount = sumNetTotal + taxAmountValue - discountValue;
    setEditNetAmount(calculatedNetAmount.toFixed(2));
  };
  const addNewEditItem = () => {
    setEditItems([...editItems, {
      itemname: '',
      rate: '',
      qty: '',
      total: '',
      discount: '',
      net_total: '',
      uom: ''
    }]);
  };
  
  const removeEditItem = (index) => {
    const updatedItems = [...editItems];
    updatedItems.splice(index, 1);
    setEditItems(updatedItems);
  };

  useEffect(()=>{
    if(invoiceEdit){
      getIndividualInvoices(InvoiceNumber)
    }

  },[invoiceEdit])

  useEffect(() => {
    if (orgID !== "" && orgID !== undefined) {
      console.log("called");
      getUsers();
    }
  }, [orgID, profile]);

  useEffect(() => {
    setEditPincode(invoiceIndividual.billingaddress?.[0]?.pin || '');
    setEditCountry(invoiceIndividual.billingaddress?.[0]?.country || '');
    setEditAddress(invoiceIndividual.billingaddress?.[0]?.address || '');
    setEditTaxAmount(invoiceIndividual.taxableamount || '');
    setEditNetAmount(invoiceIndividual.net_amount || '');
    setEditDiscount(invoiceIndividual.discount || '');
    setEditTotal(invoiceIndividual.total || '');
    setEditState(invoiceIndividual.state || '');
    setEditDate(invoiceIndividual.date || '');
  
    // Check if 'items' property exists in invoiceIndividual and is an array
    const itemsArray = invoiceIndividual.items && Array.isArray(invoiceIndividual.items)
      ? invoiceIndividual.items
      : [];
  
    setEditItems(itemsArray);
  
  }, [invoiceIndividual]);
  return (
    <div className={style.Container}>
      {!invoiceEdit?
      <div className={style.Header}>
        <div className={style.HeaderText}>
          <p>Invoice Create</p>
        </div>
      </div>
      :<div className={style.Header}>
      <div className={style.HeaderText}>
        <p>Invoice Edit</p>
      </div>
    </div>}
      <div className={style.Invoice}>
        {!invoiceEdit?
          <>
          <div className={style.invoicehead}>
            <div className={style.InvoiceNumber}>
              <div className={style.Label}>Invoice Number</div>:
            </div>
            <div className={style.InvoiceDate}>
              <div className={style.Label}>Invoice Date</div>:
              <input value={date} onChange={(e)=>{
                setDate(e.target.value)
              }} type="date" />
            </div>
          </div>
          <div className={style.UserSlector}>
          <FormControl sx={{ width: "30%", margin: "0 0.25rem" }}>
              <InputLabel id="demo-simple-select-label">
                Select User
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Select User"
                onChange={(e) => {
                  const userID = e.target.value;
                  setSelectedUser(userID);
                  const selecteduser = userList.find(user => user._id === userID);
                      const userName = selecteduser ? selecteduser.Username : '';
                      setSelectedUserName(userName);
                }}
              >
                {userList && userList.length > 0 ? (
                  userList.map((user) => (
                    <MenuItem key={user._id} value={user._id}>
                      {user.Username}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem disabled>No User available</MenuItem>
                )}
              </Select>
            </FormControl>
            
          </div>
          <div className={style.InputContainer}>
            <div className={style.Label}>Address </div>:&nbsp;
            <textarea
             autoComplete="off"
              value={address}
              onChange={(e) => {
                setAddress(e.target.value);
              }}
              placeholder="Address"
            />
          </div>
          <div className={style.InputContainer}>
            <div className={style.Label}>Pincode </div>:&nbsp;
            <input
             autoComplete="off"
              value={pincode}
              onChange={(e) => {
                setPincode(e.target.value);
              }}
              placeholder="Pincode"
            />
          </div>
          <div className={style.InputContainer}>
            <div className={style.Label}>State </div>:&nbsp;
            <input
             autoComplete="off"
              value={state}
              onChange={(e) => {
                setState(e.target.value);
              }}
              placeholder="State"
            />
          </div>
          <div className={style.InputContainer}>
            <div className={style.Label}>Country </div>:&nbsp;
            <input
             autoComplete="off"
              value={country}
              onChange={(e) => {
                setCountry(e.target.value);
              }}
              placeholder="Country"
            />
          </div>
          <p style={{ margin: "2px", textAlign: "center" }}>
            Details related Items like Mentor,Course,Batch....
          </p>
          <div className={style.SelectSection}>
            <div className={style.UserSlector}>
            <FormControl sx={{ width: "30%", margin: "0 0.25rem" }}>
              <InputLabel id="demo-simple-select-label">
                Select Category
              </InputLabel>
              <Select
               disabled={!disabledAction}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Select Category"
                onChange={(e) => {
                  const category = e.target.value;
                  setClubSelect(false)
                    setSelectedCategory(category)
                  getItemName(category)
                 
                }}
              >
                
                <MenuItem value="Course">Course</MenuItem>
                <MenuItem value="Mentor">Mentor</MenuItem>
                <MenuItem value="Batch">Batch</MenuItem>
              </Select>
            </FormControl>
            </div>
            {clubSelect?
             <div className={style.UserSlector}>
              <FormControl sx={{ width: "30%", margin: "0 0.25rem" }}>
              <InputLabel id="demo-simple-select-label">
                Select User
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Select User"
                disabled={!disabledAction}
                onChange={(e) => {
                  const clubId = e.target.value;
                  setSelectedClub(clubId)
                  getCourses(clubId)
                }}
              >
                {clubList && clubList.length > 0 ? (
                  clubList.map((club) => (
                    <MenuItem key={club._id} value={club._id}>
                      {club.ClubName}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem disabled>No User available</MenuItem>
                )}
              </Select>
            </FormControl>
            
             
           </div>
           :''
          }
          </div>
          <div className={style.UserSlector}>
          <FormControl sx={{ width: "30%", margin: "0 0.25rem" }}>
  <InputLabel id="demo-simple-select-label">
    Select Item
  </InputLabel>
  <Select
    labelId="demo-simple-select-label"
    id="demo-simple-select"
    label="Select Item"
    onChange={(e) => {
      const itemID = e.target.value;
      setSelecteditemId(itemID);
      const selectedItem = itemName.find(item => item._id === itemID);
      const Name = selectedItem ? selectedItem.Name : '';
      setSelecteditemName(Name);
      getPricing(itemID);
    }}
    disabled={itemName.length < 1}
    value={selectedItemId}
  >
    
    {itemName.map((option, index) => (
      <MenuItem key={index} value={option._id}>
        {selectedCategory === 'Course' ? option.Name :
        selectedCategory === 'Mentor' ? option.Username :
        selectedCategory === 'Batch' ? option.ClubName : ''}
      </MenuItem>
    ))}
  </Select>
</FormControl>
          
            </div>
          <div className={style.Table}>
            <table className={style.InputTable}>
              <thead>
                <tr>
                  <th>Item Name</th>
                  <th>Rate</th>
                  <th>UOM</th>
                  <th>Quantity</th>
                  <th>Total</th>
                  <th>Discount</th>
                  <th>Net Total</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {items.map((item, index) => (
                  <tr key={index}>
                    <td>
                    <input
                        type="text"
                        autoComplete="off"
                        value={item.itemname}
                        disabled={!disabledAction}
                        onChange={(e) =>
                          handleItemChange(index, "itemname", e.target.value)
                        }
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        autoComplete="off"
                        value={item.rate}
                        disabled={!disabledAction}
                        onChange={(e) =>
                          handleItemChange(index, "rate", e.target.value)
                        }
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        autoComplete="off"
                        value={item.uom}
                        disabled={!disabledAction}
                        onChange={(e) =>
                          handleItemChange(index, "uom", e.target.value)
                        }
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        autoComplete="off"
                        value={item.qty}
                        disabled={!disabledAction}
                        onChange={(e) =>
                          handleItemChange(index, "qty", e.target.value)
                        }
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        autoComplete="off"
                        value={item.total}
                        disabled={!disabledAction}
                        onChange={(e) =>
                          handleItemChange(index, "total", e.target.value)
                        }
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        autoComplete="off"
                        value={item.discount}
                        disabled={!disabledAction}
                        onChange={(e) =>
                          handleItemChange(index, "discount", e.target.value)
                        }
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        autoComplete="off"
                        value={item.net_total}
                        disabled={!disabledAction}
                        onChange={(e) =>
                          handleItemChange(index, "net_total", e.target.value)
                        }
                      />
                    </td>
                    <td>
                      <AiOutlineClose disabled={!disabledAction} onClick={() => {
                      if(disabledAction){
                        removeItem(index)
                      }
                        }} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
  
            <button disabled={!disabledAction} className={style.TableButton} style={{ textAlign: "end" }} onClick={addNewItem}>
              Add Item
            </button>
            <div className={style.InputContainer}>
              <div className={style.Label}>Total amount </div>:&nbsp;
              <input
               autoComplete="off"
                value={total}
                disabled={!disabledAction}
                onChange={(e) => {
                  setTotal(e.target.value);
                }}
                placeholder="Total amount"
              />
            </div>
            <div className={style.InputContainer}>
              <div className={style.Label}>Taxable amount </div>:&nbsp;
              <input
               autoComplete="off"
                value={taxAmount}
                disabled={!disabledAction}
                onChange={(e) => 
                 handleTaxAmountChange(e)
                }
                placeholder="Taxable amount"
              />
            </div>
            <div className={style.InputContainer}>
              <div className={style.Label}> Discount</div>:&nbsp;
              <input
                autoComplete="off"
                value={discount}
                disabled={!disabledAction}
                onChange={(e) => 
                  handleDiscountChange(e)
                }
                placeholder="Discount"
              />
            </div>
            <div className={style.InputContainer}>
              <div className={style.Label}>Net Amount </div>:&nbsp;
              <input
                autoComplete="off"
                value={netAmount}
                disabled={!disabledAction}
                onChange={(e) => {
                  setNetAmount(e.target.value);
                }}
                placeholder="Net Amount"
              />
            </div>
            <div className={style.errorMessages}>
              {Object.values(errors).map((error, index) => (
                <div key={index} className={style.errorValue}>
                  <p>{error}</p>
                </div>
              ))}
            </div>
            <div className={style.InvoiceButton}>
              <button disabled={!disabledAction} onClick={()=>{
                createInvoice()
              }}>Save Invoice</button>
            </div>
          </div>
   </>
        :<>
        <div className={style.invoicehead}>
          <div className={style.InvoiceNumber}>
            <div className={style.Label}>Invoice Number</div>:{InvoiceNumber}
          </div>
          <div className={style.InvoiceDate}>
            <div className={style.Label}>Invoice Date</div>:
            <input value={editDate} onChange={(e)=>{
              setEditDate(e.target.value)
            }} type="date" />
          </div>
        </div>
        {/* <div className={style.UserSlector}>
          <select
            name=""
            id=""
            onChange={(e) => {
              const userID = e.target.value;
              setSelectedUser(userID)
              const userName = e.target.options[e.target.selectedIndex].text;
              setSelectedUserName(userName)
            }}
          >
            <option value="" >Select User</option>
            {userList.length > 0 &&
              userList.map((user, index) => {
                return (
                  <option key={index} value={user._id}>
                    {user.Username}
                  </option>
                );
              })}
          </select>
        </div> */}
        <div className={style.InputContainer}>
          <div className={style.Label}>Address </div>:&nbsp;
          <textarea
           autoComplete="off"
            value={editAddress}
            onChange={(e) => {
              setEditAddress(e.target.value);
            }}
            placeholder="Address"
          />
        </div>
        <div className={style.InputContainer}>
          <div className={style.Label}>Pincode </div>:&nbsp;
          <input
           autoComplete="off"
            value={editPincode}
            onChange={(e) => {
              setEditPincode(e.target.value);
            }}
            placeholder="Pincode"
          />
        </div>
        <div className={style.InputContainer}>
          <div className={style.Label}>State </div>:&nbsp;
          <input
           autoComplete="off"
            value={editState}
            onChange={(e) => {
              setEditState(e.target.value);
            }}
            placeholder="State"
          />
        </div>
        <div className={style.InputContainer}>
          <div className={style.Label}>Country </div>:&nbsp;
          <input
           autoComplete="off"
            value={editCountry}
            onChange={(e) => {
              setEditCountry(e.target.value);
            }}
            placeholder="Country"
          />
        </div>
        <p style={{ margin: "2px", textAlign: "center" }}>
          Details related Items like Mentor,Course,Batch....
        </p>
        <div className={style.SelectSection}>
          <div className={style.UserSlector}>
          <FormControl sx={{ width: "30%", margin: "0 0.25rem" }}>
              <InputLabel id="demo-simple-select-label">
                Select Category
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Select Category"
                onChange={(e) => {
                  const category = e.target.value;
                  setClubSelect(false)
                    setSelectedCategory(category)
                  getItemName(category)
                 
                }}
              >
                
                <MenuItem value="Course">Course</MenuItem>
                <MenuItem value="Mentor">Mentor</MenuItem>
                <MenuItem value="Batch">Batch</MenuItem>
              </Select>
            </FormControl>
           
          </div>
          {clubSelect?
           <div className={style.UserSlector}>
           <select
           disabled={!disabledAction}
             name=""
             id=""
             onChange={(e) => {
               const clubId = e.target.value;
               setSelectedClub(clubId)
               getCourses(clubId)
             }}
           >
             <option value="">Select CLub</option>
             {clubList.length > 0 &&
              clubList.map((club,index) => {
                return (
                  <option key={index} value={club._id}>
                    {club.ClubName}
                  </option>
                );
              })}
           </select>
         </div>
         :''
        }
        </div>
        <div className={style.UserSlector}>
        <FormControl sx={{ width: "30%", margin: "0 0.25rem" }}>
  <InputLabel id="demo-simple-select-label">
    Select Item
  </InputLabel>
  <Select
    labelId="demo-simple-select-label"
    id="demo-simple-select"
    label="Select Item"
    onChange={(e) => {
      const itemID = e.target.value;
      setSelecteditemId(itemID);
      const selectedItem = itemName.find(item => item._id === itemID);
      const Name = selectedItem ? selectedItem.Name : '';
      setSelecteditemName(Name);
      getPricingEdit(itemID)
    }}
    disabled={itemName.length < 1}
    value={selectedItemId}
  >
    
    {itemName.map((option, index) => (
      <MenuItem key={index} value={option._id}>
        {selectedCategory === 'Course' ? option.Name :
        selectedCategory === 'Mentor' ? option.Username :
        selectedCategory === 'Batch' ? option.ClubName : ''}
      </MenuItem>
    ))}
  </Select>
</FormControl>
        
          </div>
        <div className={style.Table}>
          <table className={style.InputTable}>
            <thead>
              <tr>
                <th>Item Name</th>
                <th>Rate</th>
                <th>UOM</th>
                <th>Quantity</th>
                <th>Total</th>
                <th>Discount</th>
                <th>Net Total</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {editItems.map((item, index) => (
                <tr key={index}>
                  <td>
                  <input
                      type="text"
                      autoComplete="off"
                      value={item.itemname}
                     
                      onChange={(e) =>
                        handleEditItemChange(index, "itemname", e.target.value)
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      autoComplete="off"
                      value={item.rate}
                      
                      onChange={(e) =>
                        handleEditItemChange(index, "rate", e.target.value)
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      autoComplete="off"
                      value={item.uom}
                      
                      onChange={(e) =>
                        handleEditItemChange(index, "uom", e.target.value)
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      autoComplete="off"
                      value={item.qty}
                      
                      onChange={(e) =>
                        handleEditItemChange(index, "qty", e.target.value)
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      autoComplete="off"
                      value={item.total}
                     
                      onChange={(e) =>
                        handleEditItemChange(index, "total", e.target.value)
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      autoComplete="off"
                      value={item.discount}
                      
                      onChange={(e) =>
                        handleEditItemChange(index, "discount", e.target.value)
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      autoComplete="off"
                      value={item.net_total}

                      onChange={(e) =>
                        handleEditItemChange(index, "net_total", e.target.value)
                      }
                    />
                  </td>
                  <td>
                    <AiOutlineClose onClick={() => {
                    
                      removeEditItem(index)
                    }
                   } />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          <button style={{ textAlign: "end" }}className={style.TableButton} onClick={addNewEditItem}>
            Add Item
          </button>
          <div className={style.InputContainer}>
            <div className={style.Label}>Total amount </div>:&nbsp;
            <input
             autoComplete="off"
              value={editTotal}
              
              onChange={(e) => {
                setEditTotal(e.target.value);
              }}
              placeholder="Total amount"
            />
          </div>
          <div className={style.InputContainer}>
            <div className={style.Label}>Taxable amount </div>:&nbsp;
            <input
             autoComplete="off"
              value={editTaxAmount}
              
              onChange={(e) => 
                handleEditTaxAmountChange()
              }
              placeholder="Taxable amount"
            />
          </div>
          <div className={style.InputContainer}>
            <div className={style.Label}> Discount</div>:&nbsp;
            <input
              autoComplete="off"
              value={editDiscount}
              onChange={(e) => 
               handleEditDiscountChange()
              }
              placeholder="Discount"
            />
          </div>
          <div className={style.InputContainer}>
            <div className={style.Label}>Net Amount </div>:&nbsp;
            <input
              autoComplete="off"
              value={editNetAmount}
             
              onChange={(e) => {
                setEditNetAmount(e.target.value);
              }}
              placeholder="Net Amount"
            />
          </div>
          <div className={style.errorMessages}>
              {Object.values(errors).map((error, index) => (
                <div key={index} className={style.errorValue}>
                  <p>{error}</p>
                </div>
              ))}
            </div>
          <div className={style.InvoiceButton}>
            <button onClick={()=>{
              editInvoice()
            }} >Update Invoice</button>
          </div>
        </div>
 </>}
      </div>
    </div>
  );
};

export default Invoice;
