import React from "react";
import { css } from "@emotion/react";
import { SyncLoader } from "react-spinners";
import style from "./Loader.module.css";

const Loader = () => {
  return (
    <div className={style.Container}>
      <div className={style.Loader}>
        <SyncLoader color="#36D7B7" size={15} margin={5} loading={true} />
      </div>
    </div>
  );
};

export default Loader;
